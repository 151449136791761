import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

function getProtocolTypeOptionsByLocalStorage() {
  try {
    return JSON.parse(localStorage.getItem("protocolTypeOptions"))
  } catch (e) {
    return []
  }
}

function getGCShowClientIDsByLocalStorage() {
  try {
    const as = JSON.parse(localStorage.getItem("gsshows"))
    if (Object.prototype.toString.call(as) === '[object Array]') {
      return as
    }

    return []
  } catch (e) {
    return []
  }
}

function getHomeProtocolTypeByLocalStorage() {
  try {
    const s = localStorage.getItem("homeProtocolType");
    if (s === null || s === undefined) {
      return 1000
    }
    return parseInt(s)
  } catch (e) {
    return 0
  }
}

function getWebTitleByLocalStorage() {
  const title = localStorage.getItem("webTitle")
  if (title === undefined || title === null || title === "") {
    return '-'
  }

  return title
}

function getProjectNameByLocalStorage() {
  const info = localStorage.getItem("projectName")
  if (info === undefined || info === null) {
    return ''
  }

  return info
}

function getProjectURLByLocalStorage() {
  const info = localStorage.getItem("projectURL")
  if (info === undefined || info === null) {
    return ''
  }

  return info
}

function getDevByLocalStorage() {
  try {
    const s = localStorage.getItem("dev");
    if (s === null || s === undefined) {
      return 0
    }
    return parseInt(s)
  } catch (e) {
    return 0
  }
}
function getProfileAddressByLocalStorage() {
  const info = localStorage.getItem("profileAddress")
  if (info === undefined || info === null) {
    return ''
  }

  return info
}

function getDTokenByLocalStorage() {
  const dtoken = localStorage.getItem("dtoken")
  if (dtoken === undefined || dtoken === null) {
    return ''
  }

  return dtoken
}
function getChangePasswordByLocalStorage() {
  try {
    const s = localStorage.getItem("changePasswordFlag");
    if (s === null || s === undefined) {
      return false
    }
    return s === "true"
  } catch (e) {
    return false
  }
}

function getChangeFePortByLocalStorage() {
  try {
    const s = localStorage.getItem("changeFePortFlag");
    if (s === null || s === undefined) {
      return false
    }
    return s === "true"
  } catch (e) {
    return false
  }
}

export default new Vuex.Store({
  state: {
    requestState: 'init',
    homeProtocolType: getHomeProtocolTypeByLocalStorage(),
    protocolTypeOptions:  getProtocolTypeOptionsByLocalStorage(),
    webTitle: getWebTitleByLocalStorage(),
    projectName: getProjectNameByLocalStorage(),
    projectURL: getProjectURLByLocalStorage(),
    devMode: getDevByLocalStorage(),
    profileAddress: getProfileAddressByLocalStorage(),
    gcShowClients: getGCShowClientIDsByLocalStorage(),
    dToken: getDTokenByLocalStorage(),
    changePasswordFlag: getChangePasswordByLocalStorage(),
    changeFePortFlag: getChangeFePortByLocalStorage(),
    needGoogle2FAOnLogin: false,
  },
  getters: {
    requestState (state) {
      return state.requestState
    },
    homeProtocolType (state) {
      return state.homeProtocolType
    },
    protocolTypeOptions (state) {
      return state.protocolTypeOptions
    },
    webTitle (state) {
      return state.webTitle
    },
    projectName(state) {
      return state.projectName
    },
    projectURL(state) {
      return state.projectURL
    },
    devMode(state) {
      return state.devMode
    },
    profileAddress(state) {
      return state.profileAddress
    },
    gcShowClients(state) {
      return state.gcShowClients
    },
    dToken(state) {
      return state.dToken
    },
    changePasswordFlag(state) {
      return state.changePasswordFlag
    },
    changeFePortFlag(state) {
      return state.changeFePortFlag
    },
    needGoogle2FAOnLogin(state) {
      return state.needGoogle2FAOnLogin
    }
  },
  mutations: {
    requestState(state, val) {
      state.requestState = val
    },
    homeProtocolType(state, val) {
      state.homeProtocolType = val
      localStorage.setItem("homeProtocolType", val)
    },
    protocolTypeOptions(state, val) {
      state.protocolTypeOptions = val
      localStorage.setItem("protocolTypeOptions", JSON.stringify(val))
    },
    webTitle(state, val) {
      state.webTitle = val
      localStorage.setItem("webTitle", val)
    },
    projectName(state, val) {
      state.projectInfo = val
      localStorage.setItem("projectName", val)
    },
    projectURL(state, val) {
      state.projectInfo = val
      localStorage.setItem("projectURL", val)
    },
    devMode(state, val) {
      state.devMode = parseInt(val)
      localStorage.setItem("dev", val)
    },
    profileAddress(state, val) {
      state.profileAddress = val
      localStorage.setItem("profileAddress", val)
    },
    gcShowClients(state, val) {
      state.gcShowClients = val
      localStorage.setItem("gsshows", JSON.stringify(val))
    },
    dToken(state, val) {
      state.dToken = val
      localStorage.setItem("dtoken", val)
    },
    changePasswordFlag(state, val) {
      state.changePasswordFlag = val
      localStorage.setItem("changePasswordFlag", val)
    },
    changeFePortFlag(state, val) {
      state.changeFePortFlag = val
      localStorage.setItem("changeFePortFlag", val)
    },
    needGoogle2FAOnLogin(state, val) {
      state.needGoogle2FAOnLogin = val
    }
  },
  actions: {
  },
  modules: {
  }
})
