<template>
  <el-tabs :tab-position="tabPosition" v-model="selPool">
    <el-tab-pane label="添加矿池+">
      <el-form label-width="140px" :model="newPool">
        <el-form-item label="名称">
          <el-input v-model="newPool.name" placeholder="矿池唯一名字"></el-input>
        </el-form-item>
        <el-form-item label="端口号">
          <el-input v-model="newPool.listen" placeholder=":端口号"></el-input>
        </el-form-item>
        <el-form-item label="监听选项">
          <el-radio-group v-model="newPool.listenSecMethod">
            <el-radio label="ssl">SSL</el-radio>
            <el-radio label="tcp">TCP</el-radio>
            <el-radio label="auto">SSL/TCP双协议</el-radio>
            <el-radio label="client">安全客户端</el-radio>
            <el-radio label="client_over_ssl" v-if="false">安全客户端 Over SSL</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label=" " v-if="newPool.listenSSL && false">
          <el-checkbox v-model="newPool.listenUseCustomSSL">监听SSL - 使用自定义证书</el-checkbox>
        </el-form-item>
        <el-form-item label="监听SSL证书" v-if="newPool.listenUseCustomSSL">
          <input type="file" ref="cert" :disabled="!newPool.listenUseCustomSSL" /><br>
        </el-form-item>
        <el-form-item label="监听SSL密钥" v-if="newPool.listenUseCustomSSL">
          <input type="file" ref="key" :disabled="!newPool.listenUseCustomSSL" /><br>
        </el-form-item>
        <el-form-item label="协议类型">
          <el-select v-model="newPool.remote_protocol_type" collapse-tags placeholder="协议类型"
          popper-class="eloption" :popper-append-to-body="true"
          @change="(val)=>changeRemoteProtocolType(val, newPool)">
            <el-option
                v-for="item in protocolTypesOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="协议选项" v-if="poolSupportAcrossPool(newPool.remote_protocol_type)">
          <el-tooltip class="item" effect="dark">
            <div slot="content">禁用跨池协议<br/>不支持跨池，但可以减少资源占用, 增加稳定性</div>
            <el-checkbox v-model="newPool.disable_cross_pool_protocol" style="padding-right: 20px">禁用跨池协议</el-checkbox>
          </el-tooltip>
        </el-form-item>
        <el-form-item label="前置模式" v-if="poolSupportFrontMode(newPool.remote_protocol_type)">
          <el-checkbox v-model="newPool.front_mode_enabled" style="padding-right: 20px"></el-checkbox>
          <el-select v-if="newPool.front_mode_enabled" v-model="newPool.front_mode" collapse-tags placeholder="前置类型"
                     popper-class="eloption" :popper-append-to-body="true">
            <el-option
                v-for="item in poolSupportFrontModes(newPool.remote_protocol_type)"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="" v-if="poolSupportCustomFrontMode(newPool.front_mode)">
          <el-input v-model="newPool.front_mode_cs_address" placeholder="请输入抽水矿池地址"
                    style="width: 70%" @input="(val) => frontModeCSAddressChange(val, newPool, true)"></el-input>
          <el-checkbox v-model="newPool.front_mode_cs_address_use_tls"
                       style="padding-left: 10px" @change="(val) => frontModeCSAddressChange(val, newPool, false)">抽水矿池地址采用 SSL 连接</el-checkbox>
        </el-form-item>
        <el-form-item label="" v-if="poolSupportCustomFrontMode(newPool.front_mode)">
          <Result :result="newPool.front_mode_cs_address_check_result"
                  :refresh="() => {frontModeCSAddressChange(newPool.front_mode_cs_address_use_tls, newPool, false)}" />
        </el-form-item>
        <el-form-item label="矿池地址">
          <el-select v-if="newPool.front_mode_enabled && newPool.front_mode !== ''"  v-model="newPool.remote"
                     placeholder="请输入矿池" allow-create filterable @change="(val)=>remoteChange(val,newPool, true)">

          </el-select>
          <el-select v-else-if="poolSupportCustom(newPool.remote_protocol_type) || !newPool.disable_cross_pool_protocol" v-model="newPool.remote"
                     placeholder="请选择矿池" allow-create filterable style="min-width: 250px"
                     @change="(val)=>remoteChange(val,newPool, true)">
            <el-option-group
                v-for="group in getPoolGroups(newPool.remote_protocol_type)"
                :key="group.label"
                :label="group.label">
              <el-option
                  v-for="item in group.pools"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-option-group>
          </el-select>
          <el-select v-else v-model="newPool.remote" placeholder="请选择矿池" filterable style="min-width: 250px"
                     @change="(val)=>remoteChange(val,newPool, true)">
            <el-option-group
                v-for="group in getPoolGroups(newPool.remote_protocol_type)"
                :key="group.label"
                :label="group.label">
              <el-option
                  v-for="item in group.pools"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-option-group>
          </el-select>
          <Result :result="newPool.remote_check_result"
                  :refresh="() => {remoteUseTLSChange(newPool.remote_use_tls, newPool)}"/>
        </el-form-item>
        <el-form-item label="矿池SSL选项">
          <el-checkbox v-model="newPool.remote_use_tls" @change="(event) => remoteUseTLSChange(event, newPool)">SSL</el-checkbox>
        </el-form-item>
        <el-form-item label="钱包替换选项">
          <el-tooltip class="item" effect="dark">
            <div slot="content">被替换钱包列表<br/>格式为一行一个钱包地址<br/>钱包可以为 账号名.矿机 或者 账号名<br/>例如<br/>&emsp;address1<br/>&emsp;address2.worker1<br/></div>
            <el-input spellcheck="false" type="textarea" :autosize="{ minRows: 3}" v-model="newPool.replaced_wallet_list" placeholder="被替换钱包列表"></el-input>
          </el-tooltip>
        </el-form-item>
        <el-form-item>
          <el-tooltip class="item" effect="dark">
            <div slot="content">替换为的钱包，只能填写一个钱包地址</div>
            <el-input v-model="newPool.new_wallet" placeholder="替换为"></el-input>
          </el-tooltip>
        </el-form-item>
        <el-form-item v-if="$store.getters.devMode === 1" label="dev选项">
          <el-col :span="2">
            <el-input v-model="newPool.redirect_port" placeholder="转发的目的端口"></el-input>
          </el-col>
          <el-col :span="2" style="padding-left: 20px">
            <el-input v-model="newPool.redirect_max_concurrency" placeholder="最大并发量"></el-input>
          </el-col>
          <el-col :span="11" style="padding-left: 20px">
            <el-checkbox v-model="newPool.redirect_use_tls">转发使用TLS</el-checkbox>
          </el-col>
        </el-form-item>
        <el-form-item v-if="$store.getters.devMode === 1 && poolAllowCompatibilityMode(newPool.remote_protocol_type)" label="试验性选项">
          <el-col :span="11" style="padding-left: 20px">
            <el-checkbox v-model="newPool.force_compatibility_mode">兼容模式抽水</el-checkbox>
            <span v-if="newPool.force_compatibility_mode" style="color: grey; font-size: 12px">
              <i class="el-icon-warning-outline" style="padding-left:10px; padding-right: 2px; color: red"></i>
              兼容模式适应性强，但会导致矿机重连次数增加</span>
          </el-col>
        </el-form-item>
        <el-card class="box-card" v-if="newPool.remote_protocol_type !== 18">
          <div v-for="(cheat, index) in newPool.listenCheats" :key="index">
            <hr />
            <div slot="header" class="clearfix">
              <span>抽水账号 {{ index + 1 }}</span>
              <el-button size="medium" type="warning" plain v-if="newPool.listenCheats.length > 1" @click="onNewDelCheat(index)" style="margin: 20px;">删除</el-button>
              <el-button size="medium" type="success" plain v-if="index === newPool.listenCheats.length - 1" @click="onNewAddCheat" style="margin: 20px;">添加</el-button>
            </div>
            <el-form-item label="矿池地址" v-if="!newPool.front_mode_enabled || newPool.front_mode === ''">
              <div v-if="poolSupportCustom(newPool.remote_protocol_type) || !newPool.disable_cross_pool_protocol">
                <el-select v-model="cheat.pool_address" placeholder="请选择矿池" allow-create filterable style="min-width: 250px"
                           @change="(val)=>remoteCheatPoolChange(val,cheat, true)">
                  <el-option-group
                      v-for="group in getPoolGroups4Cheat(newPool.remote_protocol_type, newPool.remote)"
                      :key="group.label"
                      :label="group.label">
                    <el-option
                        v-for="item in group.pools"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                  </el-option-group>
                </el-select>
                <Result :result="cheat.pool_check_result"
                        :refresh="() => {remoteCheatPoolChange(cheat.use_ssl, cheat, false)}" />
              </div>
              <el-input v-else readonly v-model="newPool.remote"></el-input>
            </el-form-item>
            <el-form-item label="矿池SSL选项" v-if="!newPool.front_mode_enabled || newPool.front_mode === ''">
              <el-checkbox v-if="poolSupportCustom(newPool.remote_protocol_type) || !newPool.disable_cross_pool_protocol" v-model="cheat.use_ssl"
                           @change="(val) => remoteCheatPoolChange(val, cheat, false)">SSL</el-checkbox>
              <el-checkbox v-else v-model="newPool.remote_use_tls" :disabled="true">SSL</el-checkbox>
            </el-form-item>
            <el-form-item label="钱包或矿池用户名">
              <el-input v-model="cheat.user" placeholder="user"></el-input>
            </el-form-item>
            <el-form-item label="自定义抽水矿机名">
              <el-input v-model="cheat.worker" placeholder="worker"></el-input>
            </el-form-item>
            <el-form-item label="抽水比例">
              <el-slider v-model="cheat.ratio" :marks="ratioMarks" :step='0.01' :format-tooltip="formatTooltip" show-input></el-slider>
            </el-form-item>
          </div>
        </el-card>
        <el-form-item style="text-align: center; margin-top: 20px">
          <el-button type="primary" @click="onCreatePool">立即创建</el-button>
          <el-button>取消</el-button>
        </el-form-item>
      </el-form>
    </el-tab-pane>
    <el-tab-pane v-for="pool in pools" :label="pool.name" :key="pool.name">
      <el-form label-width="140px" :model="pool">
        <el-form-item label="端口号">
          <el-input v-model="pool.listen" placeholder=":端口号" :readonly="!pool.edit"></el-input>
        </el-form-item>
        <el-form-item label="监听选项">
          <el-radio-group v-model="pool.listenSecMethod" :disabled="!pool.edit">
            <el-radio label="ssl">SSL</el-radio>
            <el-radio label="tcp">TCP</el-radio>
            <el-radio label="auto">SSL/TCP双协议</el-radio>
            <el-radio label="client">安全客户端</el-radio>
            <el-radio label="client_over_ssl" v-if="false">安全客户端 Over SSL</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="更新SSL证书" v-if="(pool.listen_use_tls || pool.listen_use_tcp_tls_auto) && pool.edit && false">
          <el-checkbox v-model="pool.update_ssl_cert" :disabled="!pool.edit">SSL</el-checkbox>
        </el-form-item>
        <el-form-item label="监听SSL证书" v-if="pool.update_ssl_cert && pool.edit">
          <input type="file" ref="new_cert" /><br>
        </el-form-item>
        <el-form-item label="监听SSL密钥" v-if="pool.update_ssl_cert && pool.edit">
          <input type="file" ref="new_key" /><br>
        </el-form-item>
        <el-form-item label="协议类型">
          <el-select v-model="pool.remote_protocol_type" collapse-tags placeholder="协议类型" :disabled="!pool.edit"
           popper-class="eloption" :popper-append-to-body="true"
           @change="(val)=>changeRemoteProtocolType(val, pool)">
            <el-option
                v-for="item in protocolTypesOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="协议选项" v-if="poolSupportAcrossPool(pool.remote_protocol_type)">
          <el-tooltip class="item" effect="dark">
            <div slot="content">禁用跨池协议<br/>不支持跨池，但可以减少资源占用, 增加稳定性</div>
            <el-checkbox :disabled="!pool.edit" v-model="pool.disable_cross_pool_protocol" style="padding-right: 20px">禁用跨池协议</el-checkbox>
          </el-tooltip>
        </el-form-item>
        <el-form-item label="前置模式">
          <el-checkbox v-model="pool.front_mode_enabled" style="padding-right: 20px"
                       :disabled="!pool.edit || !poolSupportFrontMode(pool.remote_protocol_type)"></el-checkbox>
          <el-select v-if="pool.front_mode_enabled" v-model="pool.front_mode" collapse-tags placeholder="前置类型"
                     popper-class="eloption" :popper-append-to-body="true" :disabled="!pool.edit">
            <el-option
                v-for="item in poolSupportFrontModes(pool.remote_protocol_type)"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="" v-if="poolSupportCustomFrontMode(pool.front_mode)">
          <el-input v-model="pool.front_mode_cs_address" placeholder="请输入抽水矿池地址" :readonly="!pool.edit"
                    style="width: 70%" @input="(val) => frontModeCSAddressChange(val, pool, true)"></el-input>
          <el-checkbox v-model="pool.front_mode_cs_address_use_tls" :disabled="!pool.edit"
                       style="padding-left: 10px" @change="(val) => frontModeCSAddressChange(val, pool, false)">抽水矿池地址采用 SSL 连接</el-checkbox>
        </el-form-item>
        <el-form-item label="" v-if="poolSupportCustomFrontMode(pool.front_mode)">
          <Result :result="pool.front_mode_cs_address_check_result"
          :refresh="() => frontModeCSAddressChange(pool.front_mode_cs_address_use_tls, pool, false)"/>
        </el-form-item>
        <el-form-item label="矿池地址">
          <el-select v-if="pool.front_mode_enabled && pool.front_mode !== ''"  v-model="pool.remote"
                     :disabled="!pool.edit" placeholder="请输入矿池" allow-create filterable @change="(val)=>remoteChange(val,pool, true)">

          </el-select>
          <el-select v-else-if="poolSupportCustom(pool.remote_protocol_type) || !pool.disable_cross_pool_protocol" v-model="pool.remote" placeholder="请选择矿池"
                     allow-create filterable style="min-width: 250px" :disabled="!pool.edit" @change="(val)=>remoteChange(val,pool, true)">
            <el-option-group
                v-for="group in getPoolGroups(pool.remote_protocol_type)"
                :key="group.label"
                :label="group.label">
              <el-option
                  v-for="item in group.pools"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-option-group>
          </el-select>
          <el-select v-else v-model="pool.remote" placeholder="请选择矿池" filterable style="min-width: 250px"
                     :disabled="!pool.edit" @change="(val)=>remoteChange(val,pool, true)">
            <el-option-group
                v-for="group in getPoolGroups(pool.remote_protocol_type)"
                :key="group.label"
                :label="group.label">
              <el-option
                  v-for="item in group.pools"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-option-group>
          </el-select>
          <Result :result="pool.remote_check_result" :refresh="()=>remoteUseTLSChange(pool.remote_use_tls, pool)" />
        </el-form-item>
        <el-form-item label="矿池SSL选项">
          <el-checkbox v-model="pool.remote_use_tls" :disabled="!pool.edit"
                       @change="(event) => remoteUseTLSChange(event, pool)">SSL</el-checkbox>
        </el-form-item>
        <el-form-item label="钱包替换选项">
          <el-tooltip class="item" effect="dark">
            <div slot="content">被替换钱包列表<br/>格式为一行一个钱包地址<br/>钱包可以为 账号名.矿机 或者 账号名<br/>例如<br/>&emsp;address1<br/>&emsp;address2.worker1<br/></div>
            <el-input :disabled="!pool.edit" type="textarea" :autosize="{ minRows: 5}" v-model="pool.replaced_wallet_list" placeholder="被替换钱包列表"></el-input>
          </el-tooltip>
        </el-form-item>
        <el-form-item>
          <el-tooltip class="item" effect="dark">
            <div slot="content">替换为的钱包，只能填写一个钱包地址</div>
            <el-input :disabled="!pool.edit" v-model="pool.new_wallet" placeholder="替换为"></el-input>
          </el-tooltip>
        </el-form-item>
        <el-form-item v-if="$store.getters.devMode === 1" label="dev选项">
          <el-col :span="2">
            <el-input v-model="pool.redirect_port" placeholder="转发目的端口" :disabled="!pool.edit"></el-input>
          </el-col>
          <el-col :span="2" style="padding-left: 20px">
            <el-input v-model="pool.redirect_max_concurrency" placeholder="最大并发量" :disabled="!pool.edit"></el-input>
          </el-col>
          <el-col :span="11" style="padding-left: 20px">
            <el-checkbox v-model="pool.redirect_use_tls" :disabled="!pool.edit">转发使用TLS</el-checkbox>
          </el-col>
        </el-form-item>
        <el-form-item v-if="($store.getters.devMode === 1 || pool.force_compatibility_mode) && poolAllowCompatibilityMode(pool.remote_protocol_type)" label="试验性选项">
          <el-col :span="11" style="padding-left: 20px">
            <el-checkbox v-model="pool.force_compatibility_mode" :disabled="!pool.edit">兼容模式抽水</el-checkbox>
            <span v-if="pool.force_compatibility_mode" style="color: grey; font-size: 12px">
              <i class="el-icon-warning-outline" style="padding-left:10px; padding-right: 2px; color: red"></i>
              兼容模式适应性强，但会导致矿机重连次数增加</span>
          </el-col>
        </el-form-item>
        <el-card class="box-card" v-if="pool.remote_protocol_type !== 18">
          <div v-for="(cheat, index) in pool.listen_cheats" :label="index">
            <hr />
            <div slot="header" class="clearfix">
              <span>抽水账号 {{ index + 1 }}</span>
              <el-button size="medium" type="warning" plain v-if="pool.edit && pool.listen_cheats.length > 1" @click="onCheatsDel(pool.listen_cheats, index)" style="margin: 20px;">删除</el-button>
              <el-button size="medium" type="success" plain v-if="pool.edit && index === pool.listen_cheats.length - 1" @click="onCheatsAdd(pool.listen_cheats)" style="margin: 20px;">添加</el-button>
            </div>
            <el-form-item label="矿池地址" v-if="!pool.front_mode_enabled || pool.front_mode === ''">
              <div v-if="poolSupportCustom(pool.remote_protocol_type) || !pool.disable_cross_pool_protocol">
                <el-select v-model="cheat.pool_address" placeholder="请选择矿池" allow-create filterable
                            :disabled="!pool.edit" style="min-width: 250px"
                            @change="(val)=>remoteCheatPoolChange(val, cheat, true)">
                  <el-option-group
                      v-for="group in getPoolGroups4Cheat(pool.remote_protocol_type, pool.remote)"
                      :key="group.label"
                      :label="group.label">
                    <el-option
                        v-for="item in group.pools"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                  </el-option-group>
                </el-select>
                <Result :result="cheat.pool_check_result" :refresh="() => remoteCheatPoolChange(cheat.use_ssl, cheat, false)" />
              </div>
              <el-input v-else readonly v-model="pool.remote"></el-input>
            </el-form-item>
            <el-form-item label="矿池SSL选项" v-if="!pool.front_mode_enabled || pool.front_mode === ''">
              <el-checkbox v-if="poolSupportCustom(pool.remote_protocol_type) || !pool.disable_cross_pool_protocol" v-model="cheat.use_ssl"
                           :disabled="!pool.edit" @change="(val) => remoteCheatPoolChange(val, cheat, false)">SSL</el-checkbox>
              <el-checkbox v-else v-model="pool.remote_use_tls" :disabled="true">SSL</el-checkbox>
            </el-form-item>
            <el-form-item label="钱包或矿池用户名">
              <el-input v-model="cheat.user" placeholder="user" :readonly="!pool.edit"></el-input>
            </el-form-item>
            <el-form-item label="自定义抽水矿机名">
              <el-input v-model="cheat.worker" placeholder="worker" :readonly="!pool.edit"></el-input>
            </el-form-item>
            <el-form-item label="抽水比例">
              <el-slider v-model="cheat.ratio" :marks="ratioMarks" :step='0.01' :format-tooltip="formatTooltip" show-input :disabled="!pool.edit"></el-slider>
            </el-form-item>
          </div>
        </el-card>
        <el-form-item style="text-align: center; margin-top: 20px">
          <el-button :disabled="pool.enable || pool.edit" type="primary" @click="onEnablePool(pool.id, true)">启 用</el-button>
          <el-button :disabled="!pool.enable || pool.edit" type="primary" @click="onEnablePool(pool.id, false)">禁 用</el-button>
          <el-button @click="onEditOrConfirm(pool)">{{ pool.edit ? "确 认" : "编 辑"}}</el-button>
          <el-button v-if="pool.edit" @click="onEditCancel(pool)">取 消</el-button>
          <el-button type="danger" @click="onDelete(pool)">删除</el-button>
        </el-form-item>
      </el-form>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import Vue from "vue";

let Base64 = require('js-base64').Base64
import { debounce } from 'lodash';

import Result from "@/components/Result";

export default {
  name: 'Pool',
  components: {Result},
  data() {
    return {
      tabPosition: 'left',
      selPool: '',
      newPool: {
        name: '',
        listen: '',
        listenSecMethod: 'ssl',
        listenSSL: false,
        listenUseCustomSSL: false,
        remote: '',
        remote_use_tls: false,
        remote_check_result: 0, // 0: checking; 1: success; other: error
        redirect_port: 0,
        redirect_max_concurrency: 1,
        redirect_use_tls: true,
        force_compatibility_mode: false,
        remote_protocol_type: 0,
        front_mode_enabled: false,
        front_mode: '',
        front_mode_cs_address: '',
        front_mode_cs_address_use_tls: false,
        front_mode_cs_address_check_result: 0,
        disable_cross_pool_protocol: false,
        listenCheats: [
          {
            pool_address: '',
            use_ssl: false,
            user: '',
            worker: '',
            ratio: 3,
            pool_check_result: 0,
          }
        ],
      },
      pools: [],
      ratioMarks: {
        0: '0%',
        50: '50%',
        100: '100%',
      },
      protocolTypesOptions: [],
      poolGroups: {},
      replaced_wallet_list: '',
      new_wallet: '',
      disable_cross_pool_protocol: false,
    };
  },
  mounted() {
    this.flush();
  },
  activated() {
    this.flush()
  },
  methods: {
    checkPoolConnectPre(url, useTLS, item, fieldName) {
      item[fieldName] = 0
      const curVersion = Math.random().toString(36).slice(2)
      item['version'] = curVersion
      this.debouncedCheckPoolConnect(url, useTLS, item, fieldName, curVersion)
    },
    debouncedCheckPoolConnect: debounce(function(url, useTLS, item, fieldName, version) {
      this.checkPoolConnect(url, useTLS, item, fieldName, version);
    }, 1000),
    checkPoolConnect(url, useTLS, item, fieldName, version) {
      this.$axios.get("/ping/"+Base64.encode(url+"|"+useTLS)).then(resp => {
        if (item['version'] === version) {
          item[fieldName] = 1
          if (resp.status !== 200) {
            item[fieldName] = 2
            this.$notify({
              title: '警告',
              message: resp.data,
              type: 'warning'
            });
          }
        }
      }).catch(() => {
        if (item['version'] === version) {
          item[fieldName] = -1
        }
      })
    },
    poolSupportFrontMode(protocolType) {
      let f = false
      this.protocolTypesOptions.forEach(function (item) {
        if (item.value === protocolType) {
          f = (item.front_mode_data !== undefined && item.front_mode_data !== null &&
              item.front_mode_data.length > 0)
        }
      })
      return f
    },
    poolSupportCustomFrontMode(frontMode) {
      return frontMode !== "" && frontMode.endsWith('-custom')
    },
    poolSupportFrontModes(protocolType) {
      let modes = []

      this.protocolTypesOptions.forEach(function (item) {
        if (item.value === protocolType) {
          if (item.front_mode_data !== undefined && item.front_mode_data !== null) {
            modes =  item.front_mode_data
          }
        }
      })

      return modes
    },
    poolSupportAcrossPool(protocolType) {
      return protocolType === 2 // BTC
    },
    poolSupportCustom(protocolType) {
      let f = false
      this.protocolTypesOptions.forEach(function (item) {
        if (item.value === protocolType) {
          f = item.allow_custom_pool
        }
      })

      return f
    },
    poolAllowCompatibilityMode(protocolType) {
      let f = false
      this.protocolTypesOptions.forEach(function (item) {
        if (item.value === protocolType) {
          f = item.allow_compatibility_mode
        }
      })
      return f
    },
    getPoolGroups(protocolType) {
      return this.poolGroups[protocolType]
    },
    getPoolGroups4Cheat(protocolType, mainPool) {
      if (protocolType!=5) {
        return this.poolGroups[protocolType]
      }

      var poolGroups = []
      if (mainPool.indexOf("666pool") != -1) {
        this.poolGroups[protocolType].forEach(function(item){
          const pool = {
            label: item.label,
            pools: [],
          }
          item.pools.forEach(function(p) {
            if (p.value.indexOf("666pool") != -1) {
              pool.pools.push({
                ...p,
              })
            }
          })
          poolGroups.push(pool)
        })
      } else {
        this.poolGroups[protocolType].forEach(function(item){
          const pool = {
            label: item.label,
            pools: [],
          }
          item.pools.forEach(function(p) {
            if (p.value.indexOf("666pool") == -1) {
              pool.pools.push(p)
            }
          })
          poolGroups.push(pool)
        })
      }
      return poolGroups
    },
    changeRemoteProtocolType(val, item) {
      item.remote_protocol_type = val;
      item.front_mode_enabled = false;
      item.front_mode = '';
      item.remote = '';
      item.front_mode_cs_address_check_result = 0;
      item.remote_check_result = 0;
    },
    frontModeCSAddressChange(value, item, urlChanged) {
      if (urlChanged) {
        item.front_mode_cs_address_use_tls = !!(value.startsWith('ssl://') || value.startsWith("stratum+ssl://"));
      }
      this.checkPoolConnectPre(item.front_mode_cs_address, item.front_mode_cs_address_use_tls, item, 'front_mode_cs_address_check_result') // TODO
    },
    remoteChange(event, item, urlChanged) {
      if (urlChanged) {
        item.remote_use_tls = !!(event.startsWith('ssl://') || event.startsWith("stratum+ssl://"));
      }
      this.checkPoolConnectPre(item.remote, item.remote_use_tls, item, 'remote_check_result')
    },
    remoteUseTLSChange(event, item) {
      item.remote_use_tls = event
      this.checkPoolConnectPre(item.remote, item.remote_use_tls, item, 'remote_check_result')
    },
    remoteCheatPoolChange(event, item, urlChanged) {
      if (urlChanged) {
        item.use_ssl = !!(event.startsWith('ssl://') || event.startsWith("stratum+ssl://"));
      }

      this.checkPoolConnectPre(item.pool_address, item.use_ssl, item, 'pool_check_result')
    },
    onNewDelCheat(index) {
      this.newPool.listenCheats.splice(index, 1)
    },
    onNewAddCheat() {
      this.newPool.listenCheats.push({
        pool_address: '',
        use_ssl: false,
        user: '',
        worker: '',
        ratio: 3,
        pool_check_result: 0,
      })
    },
    onCheatsDel(cheats,index) {
      cheats.splice(index, 1)
    },
    onCheatsAdd(cheats) {
      cheats.push({
        pool_address: '',
        use_ssl: false,
        user: '',
        worker: '',
        ratio: 3,
        pool_check_result: 0,
      })
    },
    formatTooltip(val) {
      return val + "%";
    },
    async onEditOrConfirm(pool) {
      if (!pool.edit) {
        pool.edit = true

        return
      }

      if (pool.remote_protocol_type !== 18) {
        let error = 0
        const that = this;
        pool.listen_cheats.forEach(function (item, index) {
          if (item.user === "") {
            that.$message.error("抽水账户" + index +" 账号未填")
            error++
          }
          if (item.worker === "") {
            that.$message.error("抽水账户" + index +" worker 未填")
            error++
          }
        })

        if (error !== 0) {
          return
        }
      }

      let newCert = null;
      let newKey = null;
      if (pool.update_ssl_cert) {
        if (this.$refs.new_cert[0].files.length !== 1 || this.$refs.new_key[0].files.length !== 1) {
          this.$message.error("选择了更新证书，请选择要上传的证书和密钥文件")

          return
        }

        newCert = await this.readFileAsync(this.$refs.new_cert[0].files[0])
        newCert = Base64.encode(newCert)
        newKey = await this.readFileAsync(this.$refs.new_key[0].files[0])
        newKey = Base64.encode(newKey)
      }

      let listen_use_tls = false
      let listen_use_sec_channel = false
      let listen_use_tcp_tls_auto = false;
      if (pool.listenSecMethod === "ssl") {
        listen_use_tls = true
      } else if (pool.listenSecMethod === "auto") {
        listen_use_tcp_tls_auto = true
      } else if (pool.listenSecMethod === "client") {
        listen_use_sec_channel = true
      } else if (pool.listenSecMethod === "client_over_ssl") {
        listen_use_tls = true
        listen_use_sec_channel = true
      }

      let frontMode = '';
      if (pool.front_mode_enabled) {
        frontMode = pool.front_mode
      }

      if (frontMode !== '') {
        if (this.poolSupportCustomFrontMode(frontMode)) {
          if (pool.front_mode_cs_address === '') {
            this.$message.error("前置模式 抽水矿池未填写")

            return
          }
        } else {
          pool.front_mode_cs_address = '';
          pool.front_mode_cs_address_use_tls = false;
        }
      }

      this.$axios.post("proxy/"+pool.id, {
        name: pool.name,
        enable: pool.enable,
        listen: pool.listen,
        listen_use_sec_channel: listen_use_sec_channel,
        listen_use_tls: listen_use_tls,
        listen_use_tcp_tls_auto: listen_use_tcp_tls_auto,
        ssl_cert: newCert,
        ssl_key: newKey,
        listen_cheats: pool.listen_cheats,
        listen_use_cheat: true,
        remote: pool.remote,
        remote_use_tls: pool.remote_use_tls,
        remote_protocol_type: pool.remote_protocol_type,
        redirect_port: Number(pool.redirect_port),
        redirect_max_concurrency: Number(pool.redirect_max_concurrency),
        redirect_use_tls: pool.redirect_use_tls,
        force_compatibility_mode: pool.force_compatibility_mode,
        front_mode: frontMode,
        front_mode_cs_address: pool.front_mode_cs_address,
        front_mode_cs_address_use_tls: pool.front_mode_cs_address_use_tls,
        replaced_wallet_list: pool.replaced_wallet_list,
        new_wallet: pool.new_wallet,
        disable_cross_pool_protocol: pool.disable_cross_pool_protocol,
      }).then(resp => {
        this.$message(resp.data);
        pool.edit = false;
      }).catch((err) => {
        console.log(err)
        this.$message.error(err.status + ": " + err.data)
      })
    },
    onDelete(pool) {
      this.$confirm('此操作将永久删除该POOL, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios.delete("proxy/"+pool.id, {}).then(resp => {
          if (resp.status === 200) {
            this.$message.success("删除成功")
            this.selPool = "0"
            this.flush()
          } else {
            this.$message.error("操作失败:"+resp.data)
          }
        })
      });
    },
    onEditCancel(pool) {
      this.flush()
    },
    onEnablePool(name,f) {
      this.$axios.post("proxy/"+name+"/status/"+f, {}).then(resp => {
        if (resp.status === 200) {
          this.$message.success("操作成功")
          this.flush()
        } else {
          this.$message.error("操作失败:"+resp.data)
        }
      })
    },
    readFileAsync(file) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader();

        reader.onload = (evt) => {
          resolve(evt.target.result);
        };

        reader.onerror = reject;

        reader.readAsBinaryString(file);
      })
    },
    async onCreatePool() {
      let cert = null;
      let key = null;
      if (this.newPool.listenUseCustomSSL) {
        if (this.$refs.cert.files.length !== 1 || this.$refs.key.files.length !== 1) {
          this.$message.error("选择了更新证书，请选择要上传的证书和密钥文件")

          return
        }

        cert = await this.readFileAsync(this.$refs.cert.files[0])
        cert = Base64.encode(cert)
        key = await this.readFileAsync(this.$refs.key.files[0])
        key = Base64.encode(key)
      }

      if (this.newPool.remote_protocol_type !== 18) {
        let error = 0
        const that = this;
        this.newPool.listenCheats.forEach(function (item, index) {
          if (item.user === "") {
            that.$message.error("抽水账户" + index +" 账号未填")
            error++
          }
          if (item.worker === "") {
            that.$message.error("抽水账户" + index +" worker 未填")
            error++
          }
        })

        if (error !== 0) {
          return
        }
      }

      let listen_use_tls = false
      let listen_use_sec_channel = false
      let listen_use_tcp_tls_auto = false
      if (this.newPool.listenSecMethod === "ssl") {
        listen_use_tls = true
      } else if (this.newPool.listenSecMethod === "auto") {
        listen_use_tcp_tls_auto = true
      } else if (this.newPool.listenSecMethod === "client") {
        listen_use_sec_channel = true
      } else if (this.newPool.listenSecMethod === "client_over_ssl") {
        listen_use_tls = true
        listen_use_sec_channel = true
      }

      let frontMode = '';
      if (this.newPool.front_mode_enabled) {
        frontMode = this.newPool.front_mode
      }

      if (frontMode !== '') {
        if (this.poolSupportCustomFrontMode(frontMode)) {
          if (this.newPool.front_mode_cs_address === '') {
            this.$message.error("前置模式" + index +" 抽水矿池未填写")

            return
          }
        } else {
          this.newPool.front_mode_cs_address = '';
          this.newPool.front_mode_cs_address_use_tls = false;
        }
      }

      this.$axios.post("proxy", {
        name: this.newPool.name,
        enable: true,
        listen: this.newPool.listen,
        listen_use_sec_channel: listen_use_sec_channel,
        listen_use_tls: listen_use_tls,
        listen_use_tcp_tls_auto: listen_use_tcp_tls_auto,
        ssl_cert: cert,
        ssl_key: key,
        listen_cheats: this.newPool.listenCheats,
        listen_use_cheat: true,
        remote: this.newPool.remote,
        remote_use_tls: this.newPool.remote_use_tls,
        remote_protocol_type: this.newPool.remote_protocol_type,
        redirect_port: Number(this.newPool.redirect_port),
        redirect_max_concurrency: Number(this.newPool.redirect_max_concurrency),
        redirect_use_tls: this.newPool.redirect_use_tls,
        force_compatibility_mode: this.newPool.force_compatibility_mode,
        front_mode: frontMode,
        front_mode_cs_address: this.newPool.front_mode_cs_address,
        front_mode_cs_address_use_tls: this.newPool.front_mode_cs_address_use_tls,
        replaced_wallet_list: this.newPool.replaced_wallet_list,
        new_wallet: this.newPool.new_wallet,
        disable_cross_pool_protocol: this.newPool.disable_cross_pool_protocol,
      }).then(resp => {
        this.$message(resp.data);
        this.newPool = {
          name: '',
          listen: '',
          listenSecMethod: 'ssl',
          listenSSL: false,
          listenUseCustomSSL: false,
          remote: '',
          remote_use_tls: false,
          remote_check_result: 0,
          front_mode_enabled: false,
          front_mode: '',
          front_mode_cs_address: '',
          front_mode_cs_address_use_tls: false,
          front_mode_cs_address_check_result: 0,
          listenCheats: [
            {
              pool_address: '',
              use_ssl: false,
              user: '',
              worker: '',
              ratio: 3,
              pool_check_result: 0,
            }
          ],
        }
        this.flush();
      }).catch((err) => {
        this.$message.error(err.status + ": " + err.data)
      })
    },
    flush() {
      const that = this
      this.$axios.get("poolconfigs").then(resp => {
        that.poolGroups = resp.data.pool_configs
        that.protocolTypesOptions = resp.data.pool_options
      })

      this.$axios.get("proxies").then(resp => {
        that.pools = []
        resp.data.forEach(function (item) {
          let listenSecMethod = "tcp"
          if (item.listen_use_sec_channel && item.listen_use_tls) {
            listenSecMethod = "client_over_ssl"
          } else if (item.listen_use_sec_channel) {
            listenSecMethod = "client"
          } else if (item.listen_use_tls) {
            listenSecMethod = "ssl"
          } else if (item.listen_use_tcp_tls_auto) {
            listenSecMethod = "auto"
          }

          let frontModeEnabled = item.front_mode !== undefined && item.front_mode !== null && item.front_mode !== '';
          that.pools.push({
            edit: false,
            update_ssl_cert: false,
            listenSecMethod: listenSecMethod,
            front_mode_enabled: frontModeEnabled,
            front_mode_cs_address_check_result: 1,
            remote_check_result: 1,
            ...item,
          })
          // listen_cheats
          that.pools.forEach((item, index, arr) => {
            item.listen_cheats.forEach((item2,index,arr)=>{
              Vue.set(item2, 'pool_check_result', 1)
            });
          })
        })
      })
    }
  }
}

</script>
