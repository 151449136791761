<template>
  <div style="display: flex; flex-direction: column; height: 100%; text-align: left; grid-gap: 4px">
    <div style="display: flex;align-items: center; justify-content: center;grid-gap: 4px; padding: 2px 10px; border-bottom:1px solid #aaaa">
      <div style="width: 100%; flex-grow: 1; text-align: left" >
        <el-link :underline="false" @click="$emit('goto', client.id)">{{ client.name }}</el-link>
      </div>
      <span style="font-size: 12px; color: grey">{{client.version }}</span>
      <el-popconfirm
          title="移除后再次查看需要重新添加受控端，确定要移除吗？"
          @confirm="$emit('remove', client.id)"
      >
        <el-button type="danger" icon="el-icon-delete" circle size="mini" slot="reference"></el-button>
      </el-popconfirm>
    </div>

    <div v-if="client.errorMessage === ''" style="display: flex;align-items: center; justify-content: center; grid-gap: 4px">
      <el-tag :class="client.cpuWarnFla ? 'blink' : ''" :type="client.cpuWarnFlag ? 'danger' : 'success'"  size="small">CPU: {{ client.cpuPercent }}</el-tag>
      <el-tag :class="client.memWarnFlag ? 'blink' : ''" :type="client.memWarnFlag ? 'danger' : 'success'" size="small">内存: {{ client.memPercent }}</el-tag>
      <el-tag type="info" size="small">{{ client.startDuration }}</el-tag>
    </div>

    <el-table
        style="flex-grow: 1"
        :data="client.poolInfos"
        height="100%"
        :row-style="{height: '8px'}"
        :cell-style="{padding: '0'}"
        :stripe="true"
        border>
      <template v-slot:empty>
        <span style="display: block" :class="client.errorMessage === '' ? '': 'blink2'">{{ client.errorMessage === '' ? '无已配置的代理' : client.errorMessage  }}</span>
      </template>
      <el-table-column
          prop="poolName"
          label="矿池">
      </el-table-column>
      <el-table-column
          prop="onlineMiners"
          label="机器数">
      </el-table-column>
      <el-table-column
          prop="hashRate"
          label="总算力">
      </el-table-column>
    </el-table>
    <span style="font-size: 12px; color: grey; text-align:right">上次刷新时间: {{ client.lastUpdate }}</span>
  </div>
</template>

<script>
export default {
  name: 'GroupControlItem',
  props: {
    client: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
    }
  }
}
</script>

<style>
.el-table__cell {
  padding: 0 !important;
}
</style>