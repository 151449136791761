import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'
import axios from "axios";
import './plugins/vue-chat-scroll'

Vue.prototype.$axios = axios
axios.defaults.baseURL='/api'

axios.interceptors.request.use(
  config => {
  if (localStorage.getItem("token") !== null) {
    config.headers.token = localStorage.getItem("token");
  }
  if (localStorage.getItem("dtoken") !== null) {
    config.headers.dtoken = localStorage.getItem("dtoken");
  }
  config.headers.refer = window.location.href
  if (router.currentRoute.query.gccode) {
      config.headers.gccode = router.currentRoute.query.gccode;
  }
    return config;
  },
  err => {
    return Promise.reject(err);

  });

axios.interceptors.response.use(
  response => {
    if (response.headers.newtoken) {
        localStorage.setItem("token", response.headers.newtoken)
    }

    if (router.currentRoute.query.gccode) {
      window.location.replace("/")
    }

    return Promise.resolve(response);
    /*
    if (response.status === 200) {
      return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
     */
  },
  error => {
    if (error.response !== undefined && error.response.status === 401) {
      localStorage.removeItem("token")
      location.reload()
    } else {
      return Promise.reject(error.response);
    }
  }
);

router.beforeEach((to, from, next) => {
  document.title = store.getters.webTitle
  next()
})

Vue.config.productionTip = false

new Vue({
  axios,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
