<template>
  <div>
    <div style="text-align: center; margin-bottom: 20px">
      <el-radio-group v-model="curveSpan" @change="curveSpanChange">
        <el-radio-button label="H"></el-radio-button>
        <el-radio-button label="12H"></el-radio-button>
        <el-radio-button label="24H"></el-radio-button>
      </el-radio-group>
    </div>
    <v-chart class="chart" :option="option" :style="{height: '430px'}" autoresize />
  </div>
</template>

<script>
import "echarts";


import VChart, { THEME_KEY } from 'vue-echarts';

export default {
  Name: "EChart",
  components: {
    VChart,
  },
  provide: {
    [THEME_KEY]: '',
  },
  data() {
    return {
      curveSpan: 'H',
      option : {
        tooltip: {
          trigger: 'axis',
        },
        title: {
          subtext: "H/s",
          left: 60,
          top: 16,
          subtextStyle:{
            color:"#000"
          }
        },
        legend: {
          data: ['总算力','抽水算力']
        },
        xAxis: {
          type: 'category',
          data: [] //x轴数组
        },
        yAxis: {
          type: 'value',
        },
        series: [{
          name:'总算力',
          data: [],
          type: 'line',
          showBackground: true,
          areaStyle: {},
          smooth: true,
          markLine : {
            precision: 6,
            data : [
              {type : 'average'}
            ],
            label: {
              position: "middle",
            }
          }
        }, {
          name:'抽水算力',
          data: [],
          type: 'line',
          areaStyle: {},
          smooth: true,
          markLine : {
            precision: 6,
            data : [
              {type : 'average'}
            ]
          }
        }]
      },
    }
  },
  methods:{
    curveSpanChange() {
      this.$emit('curveSpanChanged')
    },
    show(xAx, yAy, yAy2, yUnit) {
      // const xAx = ["","","","5-4","5-5","5-6","5-7","5-8"];
      // const yAy = [9,8,1,3,5,4,7,6,9,8,1,3,5,4,7,6];
      // const yAy2 = [5,7,8,1,5,0,6,7];

      this.option.title.subtext = yUnit
      this.option.xAxis.data = xAx;
      this.option.series[0].data = yAy;
      this.option.series[1].data = yAy2;
    }
  },
}
</script>
