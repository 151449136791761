<template>
  <div>
    <el-tabs tab-position="left" @tab-click="handleClick" v-model="selPool">
      <el-tab-pane v-for="pool in pools" :label="pool.name" :name="pool.id">
        <el-table
            :data="miners[pool.id]"
            @cell-mouse-enter="enterSelectionRows"
            @cell-mouse-leave="leaveSelectionRows"
            :row-class-name="tableRowClassName"
            style="width: 100%">
          <el-table-column prop="account" label="账户">
          </el-table-column>
          <el-table-column prop="online" label="在线" sortable>
            <template slot-scope="{ row }">
              <span>{{ text4Online(row.online) }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="hashrate" label="实时算力" sortable>
            <template slot-scope="{ row }">
              <span>{{ text4Hashrate(row.hashrate, row.friendly_hashrate) }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="share_count" label="提交share数">
          </el-table-column>
          <el-table-column prop="addr" label="IP地址">
          </el-table-column>
          <el-table-column prop="connect_duration" label="持续时间" sortable :sort-method="sortByDuration">
          </el-table-column>
          <el-table-column prop="connect_count_24h" label="24小时连接次数" sortable>
          </el-table-column>
          <el-table-column prop="instance_count" label="机器数">
          </el-table-column>
          <el-table-column prop="capture_flag" label="capture" v-if="$store.getters.devMode">
            <template slot-scope="{ row }">
              <span><el-checkbox v-model="row.capture_flag" @change="(val) => captureSwitch(pool.id, row.account, val)">on</el-checkbox></span>
            </template>
          </el-table-column>
          <el-table-column  label="disconnect" v-if="$store.getters.devMode">
            <template slot-scope="{ row }">
              <el-button type="danger" @click="pushDisconnectCommand(pool.id, row.account)">断开</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
export default {
  name: 'User',
  data() {
    return {
      miners: [],
      pools: [],
      selPool: '',
      prettyHashrate: null,
    };
  },
  mounted() {
    this.flush();
  },
  activated() {
    this.flush();
  },
  methods: {
    tableRowClassName({row, rowIndex}) {
      if (row.cs_in_failed_count > 0) {
        return 'fail-row';
      } else if (row.cs_in_miner_pool_count > 0) {
        return 'warn-row';
      }
      return '';
    },
    createTips(el, row, value) {
      const { id } = row
      const top = el.clientY +(document.body.scrollTop || document.documentElement.scrollTop || window.pageYOffset) + 5
      const left = el.clientX +document.body.scrollLeft || document.documentElement.scrollLeft || window.pageXOffset
      const tooltipDom = document.createElement('div')
      tooltipDom.style.cssText = `
        display: inline-block;
        max-width: 800px;
        max-height: 400px;
        position: absolute;
        top: ${top}px;
        left: ${left}px;
        padding:5px 10px;
        overflow: auto;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #595959;
        background: #fff;
        border-radius: 5px;
        z-index: 19999;
        box-shadow: 0 4px 12px 1px #ccc;
      `
      tooltipDom.innerHTML = value.replaceAll('\n', '<p>')
      tooltipDom.setAttribute('id', `tooltip-${id}`)
      // 将浮层插入到body中
      document.body.appendChild(tooltipDom)
    },
    removeTips(row) {
      const {id} = row
      const tooltipDomLeave = document.querySelectorAll(`#tooltip-${id}`)
      if (tooltipDomLeave.length) {
        tooltipDomLeave.forEach(dom => {
          document.body.removeChild(dom)
        })
      }
    },
    enterSelectionRows(row, column, cell, event) {
      if (row.cs_status) {
        this.createTips(event, row, row.cs_status)
        return
      }
    },
    leaveSelectionRows(row) {
      this.removeTips(row)
    },
    pushDisconnectCommand(poolID, account) {
      this.$axios.post("/dev/disconnect/"+poolID+"/"+account).then(resp => {
        this.$message.success('断开 ['+account+'] 的请求发送成功');
      })
    },
    captureSwitch(poolID, account, val) {
      let op = 'remove'
      if (val) {
        op = 'add'
      }

      this.$axios.post("/dev/capture/"+op+"/"+poolID+"/"+account).then(resp => {

      })
    },
    sortByDuration(item1, item2) {
      let n = item1.connect_duration_seconds - item2.connect_duration_seconds;
      if (n > 0) {
        return 1
      }

      if (n < 0) {
        return -1
      }

      return 0
    },
    handleClick(tab) {
      const that = this
      this.$axios.get("miners/"+tab.name).then(resp => {
        that.miners = []
        that.miners[tab.name] = resp.data
      })
    },
    flush() {
      const that = this
      this.$axios.get("proxies/name").then(resp => {
        that.pools = resp.data
        if (that.pools.length > 0) {
          if (that.selPool === "" || that.selPool === "0") {
            that.selPool = that.pools[0].id
          }
          that.handleClick({name: that.selPool})
        }
      })
    },
    text4Online(f) {
      if (f) {
        return "是"
      }
      return "否"
    },
    text4Hashrate(hr, hrT) {
      if (hrT !== undefined && hrT !== null && hrT !== "") {
        return hrT;
      }

      if (hr < 1000) {
        return hr + " H/s"
      } else if (hr < 1000*1000) {
        return (hr /1000).toFixed(2) + " KH/s"
      } else if (hr < 1000*1000*1000) {
        return (hr /1000/1000).toFixed(2) + " MH/s"
      } else if (hr < 1000*1000*1000*1000) {
        return (hr /1000/1000/1000).toFixed(2) + " GH/s"
      } else {
        return (hr /1000/1000/1000/1000).toFixed(2) + " TH/s"
      }

      if (this.prettyHashrate === undefined || this.prettyHashrate === null) {
        return "-"
      }
      return this.prettyHashrate(hr)
    }
  }
}

</script>

<style>
.el-table .warn-row {
  background: lightgoldenrodyellow;
}

.el-table .fail-row {
  background: lightcoral;
}
</style>