<template>
  <el-form label-width="180px">
    <el-dialog title="开启登录二次验证" :visible.sync="dlg2FAVisible"
               @open="google2FADialogOpen"
               :close-on-click-modal="false" :close-on-press-escape="false" >
      <el-row style="margin: 20px">
        <el-col style="text-align: center">
          <h4>请使用Google身份验证器扫描下面二维码</h4>
          <vue-qr
              ref="qrCode"
              :text="google2FAQR"
              width="200"
              height="200"
          />
          <div>{{ google2FASecret }}</div>
          <el-divider></el-divider>
          <h4>请输入Google身份验证器上显示的验证码来验证</h4>

          <div style="display: flex; flex-direction: row">
            <el-input v-model="google2FACode" ref="inputGoogle2FACode" placeholder="请输入验证码"></el-input>
            <el-button type="primary" style="margin-left: 8px" @click="google2FAVerify">验证</el-button>
          </div>
        </el-col>
      </el-row>
    </el-dialog>
    <el-form-item label="Token(凭证)">
    </el-form-item>
      <el-form-item label=" ">
      {{ token }}
      </el-form-item>
    <el-form-item label="管理页面端口">
    </el-form-item>
    <el-form-item label=" ">
      <el-input v-model="feListen" placeholder="ip:port 形式" style="max-width: 400px" :readonly="feListenReadOnly"></el-input>
      <span style="padding-left: 20px">
        <el-button type="primary" @click="changeFeListen">{{ feListenText() }}</el-button>
        <el-button @click="feListenReadOnly=true;feListen=feListenOri" v-if="!feListenReadOnly">取消</el-button>
      </span>
    </el-form-item>
    <el-form-item label="WEB后台证书">
    </el-form-item>
    <el-form-item label=" " :inline="true">
       <span v-if="feCertEdit">
        证书:
          <input type="file" ref="cert" />
        私钥:
          <input type="file" ref="key"  />
       </span>
      <el-button type="primary" @click="changeFeSSL">{{ feListenSSLText() }}</el-button>
      <el-button type="danger" v-if="hasFeCert && !feCertEdit" @click="removeFeSSL">删除自定义证书</el-button>
      <el-button  v-if="feCertEdit" @click="feCertEdit=false">取消</el-button>
    </el-form-item>
    <el-form-item label="管理页面账户">
    </el-form-item>
    <el-form-item label=" ">
      <el-input v-model="userName" placeholder="用户名" :readonly="!accountEdit" style="max-width: 400px"></el-input>
      <el-input v-model="password" placeholder="密码已保存" :readonly="!accountEdit"
                style="max-width: 400px; margin-left: 20px"></el-input>
      <span style="padding-left: 20px">
        <el-button type="primary" @click="changeAccount" :disabled="accountEdit && checkPasswordCode !== 0">{{ accountEdit ? "应用" : "修改" }}</el-button>
        <el-button @click="accountEdit=false;userName=userNameOri;password='';checkPasswordCode=0;passwordCheckResult=''" v-if="accountEdit">取消</el-button>
      </span>
    </el-form-item>
    <el-form-item label="">
      <span style="color: red" v-if="checkPasswordCode !== 0">{{ passwordCheckResult  }}</span>
    </el-form-item>
    <el-form-item label="矿机连接证书">
    </el-form-item>
    <el-form-item label=" ">
      <span v-if="serviceCusCertEdit">
        证书:
        <input type="file" ref="cus_cert" />
        私钥:
        <input type="file" ref="cus_key"  />
      </span>
      <el-button type="primary" @click="changeServerCusSSL">{{ serverCusCertText() }}</el-button>
      <el-button type="danger" v-if="hasServerCusCert && !serviceCusCertEdit" @click="removeServerCusSSL">删除自定义证书</el-button>
      <el-button  v-if="serviceCusCertEdit" @click="serviceCusCertEdit=false">取消</el-button>
    </el-form-item>
    <el-form-item label="配置文件备份|还原">
    </el-form-item>
    <el-form-item label=" ">
      <span v-if="importEdit">
        数据文件: <input type="file" ref="backup" />
      </span>
      <el-button type="primary" v-if="!importEdit" @click="exportBackup">备份</el-button>
      <el-button type="primary" @click="importBackup">还原</el-button>
      <el-button  v-if="importEdit" @click="importEdit=false">取消</el-button>
     </el-form-item>
    <el-form-item label="邮件SMTP配置">
    </el-form-item>
    <el-form-item>
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="4">
          <div class="tbSpace">
            <el-input v-model="emailHost" placeholder="smtp地址,例如 smtp.163.com:25" :readonly="!emailEdit"></el-input>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="4">
          <div class="tbSpace">
          <el-input v-model="emailUser" placeholder="smtp用户名" :readonly="!emailEdit"></el-input>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="4">
          <div class="tbSpace">
          <el-input v-model="emailPassword" placeholder="密码已保存" :readonly="!emailEdit"></el-input>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="4" v-if="emailEdit">
          <div class="tbSpace">
            <el-input v-model="emailTestTo" placeholder="接收测试邮件的邮箱地址"></el-input>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="4">
          <div class="tbSpace">
            <Result v-if="smtpTestResult !== -100" :result="smtpTestResult" :tip="smtpTestResultTip" />
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="4">
          <div class="tbSpace">
            <el-button v-if="!emailEdit" type="primary" @click="emailEdit = true">修改</el-button>
            <el-button v-if="emailEdit" type="warning" @click="testSMTP">测试</el-button>
            <el-button v-if="emailEdit" type="primary" @click="saveSMTP">确定</el-button>
            <el-button v-if="emailEdit" @click="emailEdit = false; emailHost=emailHostOri;emailUser=emailUserOri;emailPassword=''">取消</el-button>
          </div>
        </el-col>
      </el-row>
    </el-form-item>
    <el-form-item label="邮件通知配置">
    </el-form-item>
    <el-form-item label="">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="8">
          <el-form label-position="right" label-width="180px" :model="mailNotifies">
            <el-form-item label="抽水矿池连接失败" style="padding: 20px 0">
              <el-input v-model="mailNotifies.csOffline" :readonly="!emailNotifyEdit"
                        placeholder="需要通知的邮件地址"></el-input>
            </el-form-item>
            <el-form-item label="系统资源不足" style="padding: 20px 0">
              <el-input v-model="mailNotifies.insufficientSystemResources" :readonly="!emailNotifyEdit"
                        placeholder="需要通知的邮件地址"></el-input>
            </el-form-item>
            <el-form-item style="padding: 20px 0">
              <el-button v-if="!emailNotifyEdit" type="primary" @click="emailNotifyEdit = true">修改</el-button>
              <el-button v-if="emailNotifyEdit" type="primary" @click="saveNotifyEmails">确定</el-button>
              <el-button v-if="emailNotifyEdit" @click="cancelNotifyEmailsChange">取消</el-button>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
    </el-form-item>
    <el-form-item label="谷歌二次验证(登录)">
    </el-form-item>
    <el-form-item label="">
      <el-button v-if="google2faFlag" type="primary" @click="disable2FA">取消二次验证</el-button>
      <el-button v-else type="primary" @click="enable2FA">启用二次验证</el-button>
    </el-form-item>
    <el-form-item label="开发者选项">
    </el-form-item>
    <el-form-item label=" ">
      <el-checkbox v-model="dev_checked" @change="devModeChange">开启调试功能</el-checkbox>
      {{ profile_address }}
    </el-form-item>
    <el-form-item label="">
      <el-checkbox v-model="saveHrFlag" @change="saveHrFlagChange">保存算力数据</el-checkbox>
    </el-form-item>

    <el-form-item label=" " v-if="$route.query.dev === '1234566'">
      <el-input v-model="devToken" placeholder="token" style="width: 100px" @keyup.enter.native="setDevToken"></el-input>
    </el-form-item>
  </el-form>
</template>

<script>
import {Base64} from "js-base64";
import {saveAs} from 'file-saver';
import VueQr from 'vue-qr'
import Result from "@/components/Result";
import {debounce} from "lodash-es";

export default {
  name: 'Setting',
  components: {Result, VueQr},
  data() {
    return {
      dlg2FAVisible: false,
      feListenReadOnly: true,
      feListenOri: '',
      feListen: '',
      hasFeCert: false,
      feCertEdit: false,
      hasServerCusCert: false,
      serviceCusCertEdit: false,
      userNameOri: '',
      userName: '',
      password: '',
      accountEdit: false,
      token: '',
      importEdit: false,
      dev_checked: false,
      profile_address: '',
      emailEdit: false,
      emailHost: '',
      emailHostOri: '',
      emailUser: '',
      emailUserOri: '',
      emailPassword: '',
      emailPasswordOri: '',
      emailTestTo: '',
      smtpTestResult: -100,
      smtpTestResultTip: '等待测试',
      emailNotifyEdit: false,
      mailNotifies: {
        csOffline: '',
        csOfflineOri: '',
        insufficientSystemResources: '',
        insufficientSystemResourcesOri: '',
      },
      devToken: '',
      saveHrFlag: true,
      google2faFlag: false,
      google2FAQR: '',
      google2FASecret: '',
      google2FACode: '',
      passwordCheckResult: '',
      checkPasswordCode: 0,
    }
  },
  mounted() {
    this.flush();
  },
  activated() {
    this.flush()
  },
  methods: {
    google2FADialogOpen() {
      this.google2FACode = ''
      this.$nextTick(function () {
        this.$refs.inputGoogle2FACode.focus();
      });
    },
    newPasswordChangeKeyup() {
      if (!this.accountEdit) {
        return
      }

      this.checkPasswordCode = -1
      this.passwordCheckResult = "密码检测中..."
      this.newPasswordChangeKeyupR()
    },
    newPasswordChangeKeyupR: debounce(function () {
      this.$axios.post("check/password", {
        "password": this.password
      }).then(resp => {
        if (!this.accountEdit) {
          return
        }
        this.passwordCheckResult = resp.data.msg
        this.checkPasswordCode = resp.data.code
      }).catch(() => {
        if (!this.accountEdit) {
          return
        }
        this.passwordCheckResult = '未知错误'
        this.checkPasswordCode = -1
      })
    }, 1000),
    disable2FA() {
      this.$axios.post("2fa/cancel", {}).then(resp => {
        this.google2faFlag = false
        this.$message.success('关闭谷歌二次验证成功');
      })
    },
    google2FAVerify() {
      this.$axios.post("2fa/confirm", {
        code: this.google2FACode,
      }).then(resp => {
        if (resp.data.status === 0) {
          this.$message.success('开启谷歌二次验证成功');
          this.dlg2FAVisible = false
          this.google2FAQR = ''
          this.google2FASecret = ''
          this.google2faFlag = true
          this.google2FACode = ''
        } else {
          this.$message.error(resp.data.msg);
        }
      })
    },
    enable2FA() {
      this.$axios.post("2fa/secret", {}).then(resp => {
        this.google2FAQR = resp.data.qr
        this.google2FASecret = resp.data.secret
        this.dlg2FAVisible = true
      })
    },
    setDevToken() {
      this.$axios.post("/set_dev_token/"+this.devToken, {}).then(resp => {
        this.devToken = ''
        console.log(resp.data)
        if (resp.data.startsWith('@')) {
          this.$store.commit("dToken", resp.data.slice(1))
        } else if (resp.data.startsWith('!')) {
          this.$store.commit("dToken", '')
        }
      })
    },
    cancelNotifyEmailsChange() {
      this.emailNotifyEdit = false
      this.mailNotifies.csOffline = this.mailNotifies.csOfflineOri
      this.mailNotifies.insufficientSystemResources = this.mailNotifies.insufficientSystemResourcesOri
    },
    saveNotifyEmails() {
      this.$axios.post("/notify_emails", {
        cs_pool_offline: this.mailNotifies.csOffline,
        insufficient_system_resources: this.mailNotifies.insufficientSystemResources,
      }).then(resp => {
        this.$message(resp.data);
        this.emailNotifyEdit = false;
        this.flush();
      })
    },
    smtpTestUIWaitTest() {
      this.smtpTestResult = 0
      this.smtpTestResultTip = "开始测试"
    },
    smtpTestUIWaitTestResult(success, errMsg) {
      if (success) {
        this.smtpTestResult = 1
        this.smtpTestResultTip = "SMTP测试通过"
      } else {
        this.smtpTestResult = -1
        this.smtpTestResultTip = "SMTP测试失败"
        if (errMsg) {
          this.smtpTestResultTip += " :" + errMsg
        }
      }
    },
    saveSMTP() {
      let password = this.emailPassword
      if (password === "") {
        password = this.emailPasswordOri
      }
      this.$axios.post("/smtp", {
        smtp_host: this.emailHost,
        smtp_user: this.emailUser,
        smtp_password: password,
      }).then(resp => {
        this.$message(resp.data);
        this.emailEdit = false;
        this.flush();
      })
    },
    testSMTP() {
      this.smtpTestUIWaitTest()
      let password = this.emailPassword
      if (password === "") {
        password = this.emailPasswordOri
      }
      const query = this.emailHost+"#"+this.emailUser+"#"+ password +"#"+this.emailTestTo
      this.$axios.get("/smtptest/"+Base64.encode(query)).then(resp => {
        if (resp.data.success) {
          this.smtpTestUIWaitTestResult(true)
        } else {
          this.smtpTestUIWaitTestResult(false, resp.data.error)
        }
      }).catch(() => {
        this.smtpTestUIWaitTestResult(false)
      })
    },
    devModeChange(val) {
      let devMode = "0";
      if (val) {
        devMode = "1"
      }
      this.$axios.post("/dev/mode/"+devMode).then(resp => {
        this.$store.commit("devMode", devMode)
      })
    },
    saveHrFlagChange(val) {
      this.$axios.post("/dev/save/hr/"+val).then(resp => {
        this.$message.success(resp.data)
      })
    },
    exportBackup() {
      const tm = (new Date()).valueOf()
      this.$axios.get("export?tm="+tm, {responseType: 'blob'}).then(resp => {
        saveAs(resp.data, "exported_config.dat");
      })
    },
    async importBackup() {
      if (this.importEdit) {
        const loading = this.$loading({
          lock: true,
          text: '导入中，请等待...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        let d = await this.readFileAsync(this.$refs.backup.files[0])
        d = Base64.encode(d)
        this.$axios.post("import", {
          "data": d,
        }).then(resp => {
          this.$message(resp.data);
          this.importEdit = false;
          setTimeout(() => {
            loading.close();
            location.reload();
          }, 15000);
        }).catch((err) => {
          loading.close();
          console.log(err)
          this.$message.error(err.status + " " + err.data)
        })
        return
      }
      this.importEdit = true
    },
    changeAccount() {
      if (!this.accountEdit) {
        this.accountEdit = true

        return
      }

      this.$axios.post("config/account", {
        "user_name": this.userName,
        "password": this.password,
      }).then(resp => {
        this.$message(resp.data);
        this.accountEdit = false;
        this.flush();
      }).catch((err) => {
        console.log(err)
        this.$message.error(err.status + " " + err.data)
      })
    },
    changeFeListen() {
      if(this.feListenOri === this.feListen) {
        this.feListenReadOnly = false

        return
      }

      this.$axios.post("config/fe/listen", {
        "listen": this.feListen,
      }).then(resp => {
        this.$message(resp.data);
        this.feListenReadOnly = true;
      }).catch((err) => {
        console.log(err)
        this.$message.error(err.status + " " + err.data)
      })
    },
    readFileAsync(file) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader();

        reader.onload = (evt) => {
          resolve(evt.target.result);
        };

        reader.onerror = reject;

        reader.readAsText(file);
      })
    },
    clearInputFile(f){
      if(f.value){
        try{
          f.value = ''; //for IE11, latest Chrome/Firefox/Opera...
        }catch(err){ }
        if(f.value){ //for IE5 ~ IE10
          var form = document.createElement('form'),
              parentNode = f.parentNode, ref = f.nextSibling;
          form.appendChild(f);
          form.reset();
          parentNode.insertBefore(f,ref);
        }
      }
    },
    removeFeSSL() {
      this.$axios.post("config/fe/ssl", {
        "ssl_cert": null,
        "ssl_key": null,
      }).then(resp => {
        this.$message(resp.data);
        this.flush();
      }).catch((err) => {
        console.log(err)
        this.$message.error(err.status + " " + err.data)
      })
    },
    async changeFeSSL() {
      if (!this.feCertEdit) {
        this.feCertEdit = true

        return
      }

      let cert = null;
      let key = null;

      if (this.$refs.cert.files.length === 1 || this.$refs.key.files.length === 1) {
        if (this.$refs.cert.files.length !== 1 || this.$refs.key.files.length !== 1) {
          this.$message.error("证书和密钥需要同时更新")

          return
        }

        cert = await this.readFileAsync(this.$refs.cert.files[0])
        cert = Base64.encode(cert)
        key = await this.readFileAsync(this.$refs.key.files[0])
        key = Base64.encode(key)
      }

      this.$axios.post("config/fe/ssl", {
        "ssl_cert": cert,
        "ssl_key": key,
      }).then(resp => {
        this.$message(resp.data);
        this.clearInputFile(this.$refs.cert);
        this.clearInputFile(this.$refs.key);
        this.feCertEdit = false;
        this.flush();
      }).catch((err) => {
        console.log(err)
        this.$message.error(err.status + " " + err.data)
      })
    },
    flush() {
      this.dev_checked = this.$store.getters.devMode === 1;
      this.profile_address = this.$store.getters.profileAddress;

      const that = this;
      this.$axios.get("config").then(resp => {
        that.feListenOri = resp.data.fe_listen;
        that.feListen = that.feListenOri;
        that.hasFeCert = resp.data.has_fe_cert;
        that.hasServerCusCert = resp.data.has_cus_server_cert;
        that.userNameOri = resp.data.user_name;
        that.userName = that.userNameOri;
        that.password = '';
        that.token = resp.data.token;
        that.emailHostOri = resp.data.smtp.smtp_host
        that.emailHost = that.emailHostOri
        that.emailUserOri = resp.data.smtp.smtp_user
        that.emailUser = that.emailUserOri
        that.emailPasswordOri = resp.data.smtp.smtp_password
        that.emailPassword = ''
        that.mailNotifies.csOffline = resp.data.email_users.cs_pool_offline
        that.mailNotifies.csOfflineOri = that.mailNotifies.csOffline
        that.mailNotifies.insufficientSystemResources = resp.data.email_users.insufficient_system_resources
        that.mailNotifies.insufficientSystemResourcesOri = that.mailNotifies.insufficientSystemResources
        that.saveHrFlag = resp.data.save_hr_flag
        that.google2faFlag = resp.data.google_2_fa_flag
      })
    },
    feListenText() {
      if (this.feListen === this.feListenOri) {
        return "修改"
      }

      return "应用"
    },
    feListenSSLText() {
      if (this.hasFeCert) {
        return "更新"
      }

      return "设置"
    },
    serverCusCertText() {
      if (this.hasServerCusCert) {
        return "更新"
      }

      return "设置"
    },
    removeServerCusSSL() {
      this.$axios.post("config/server/ssl", {
        "ssl_cert": null,
        "ssl_key": null,
      }).then(resp => {
        this.$message(resp.data);
        this.flush();
      }).catch((err) => {
        console.log(err)
        this.$message.error(err.status + " " + err.data)
      })
    },
    async changeServerCusSSL() {
      if (!this.serviceCusCertEdit) {
        this.serviceCusCertEdit = true

        return
      }

      let cert = null;
      let key = null;

      if (this.$refs.cus_cert.files.length !== 1 || this.$refs.cus_key.files.length !== 1) {
        this.$message.error("请选择自定义证书和密钥")

        return
      }

      if (this.$refs.cus_cert.files.length !== 1 || this.$refs.cus_key.files.length !== 1) {
        this.$message.error("证书和密钥需要同时更新")

        return
      }

      cert = await this.readFileAsync(this.$refs.cus_cert.files[0])
      cert = Base64.encode(cert)
      key = await this.readFileAsync(this.$refs.cus_key.files[0])
      key = Base64.encode(key)

      this.$axios.post("config/server/ssl", {
        "ssl_cert": cert,
        "ssl_key": key,
      }).then(resp => {
        this.$message(resp.data);
        this.clearInputFile(this.$refs.cus_cert);
        this.clearInputFile(this.$refs.cus_key);
        this.serviceCusCertEdit = false;
        this.flush();
      }).catch((err) => {
        console.log(err)
        this.$message.error(err.status + " " + err.data)
      })
    }
  }
}
</script>

<style>
.tbSpace {
  padding-top: 10px;
  padding-bottom: 10px;
}
</style>