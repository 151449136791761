<template>
  <span class="result-span">
    <i :class="iconClass()"></i> {{ tipMessage() }}
    <el-button icon="el-icon-refresh" type="warning" circle size="mini" plain
    v-if="refresh !== null" @click="refresh"></el-button>
  </span>
</template>

<script>
export default {
  name: 'Result',
  props: {
    result: {
      default: 0
    },
    tip: {
      type: String
    },
    refresh: {
      default: null,
    }
  },
  data() {
    return {}
  },
  methods: {
    iconClass() {
      if (this.result === 0 ) {
        return "el-icon-loading result-icon-loading"
      } else if (this.result === 1 ) {
        return "el-icon-success result-icon-success"
      } else if (this.result === 2 ) {
        return "el-icon-success result-icon-loading"
      }

      return "el-icon-error result-icon-error"
    },
    tipMessage() {
      if (this.tip !== "" && this.tip !== undefined && this.tip !== null) {
        return this.tip
      }
      if (this.result === 0 ) {
        return "等待或联通性检测中..."
      } else if (this.result === 1 ) {
        return "连接成功"
      } else if (this.result === 2 ) {
        return "连接成功，建议使用SSL模式"
      }

      return "连接失败"
    }
  }
}
</script>

<style>
.result-span {
  padding-left: 10px;
}
.result-icon-loading {
  color: darkgoldenrod;
}
.result-icon-success {
  color:lightgreen;
}
.result-icon-error {
  color: orangered;
}

</style>