<template>
  <div>
    <el-select v-model="selMiner" placeholder="请选择矿机" filterable style="min-width: 250px" @change="minerChange">
      <el-option
          v-for="miner in miners"
          :key="miner"
          :label="miner"
          :value="miner">
      </el-option>
    </el-select>
    <ul v-chat-scroll="{always: true, smooth: true, notSmoothOnInit: true}" style="height: 70vh; list-style: none; overflow-y:scroll">
      <li v-for="log in logs">
        {{ log }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'MinersLogger',
  props: ["poolID"],
  data() {
    return {
      selMiner: '',
      miners: [],
      logs: [],
    };
  },
  created() {
    this.flush();
  },
  destroyed() {
    if ( this.websock !== undefined) {
      this.websock.close()
    }
  },
  methods: {
    minerChange() {
      this.logs = []
      this.initWebSocket()
    },
    flush() {
      const that = this
      this.$axios.get("miners/"+this.poolID).then(resp => {
        that.miners = []
        resp.data.forEach(function (item) {
          that.miners.push(item.account)
        })
      })
    },
    initWebSocket() {
      if (this.websock !== undefined) {
        this.websock.close()
      }

      let scheme = "ws:"
      if (window.location.protocol === "https:") {
        scheme = "wss:"
      }

      this.websock = new WebSocket(scheme +"//"+window.location.host+"/xws/ws/miner/"+this.poolID+"/"+this.selMiner
          +"?token="+localStorage.getItem("token"));
      this.websock.onmessage = this.onWsMessage;
      this.websock.onopen = this.onWsOpen;
      this.websock.onerror = this.onWsError;
      this.websock.onclose = this.oncWsClose;
    },
    onWsOpen(){
      console.log('连接')
    },
    onWsError(){
      setTimeout(this.initWebSocket, 1000)
    },
    onWsMessage(e) {
      const ds = e.data.split('\n')
      ds.forEach(item => {
        if (item !== "") {
          this.logs.push(item)
        }
      })

    },
    wsSend(Data){
      this.websock.send(Data);
    },
    oncWsClose(e){  //关闭
      console.log('断开连接',e);
    },
  }
}
</script>