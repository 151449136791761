<template>
  <div>
    <el-tabs tab-position="left" v-model="selPool">
      <el-tab-pane v-for="pool in pools" :label="pool.name" :name="pool.name">
        <el-tabs type="card" v-model="pool.selTab" v-if="pool.name === selPool">
          <el-tab-pane name="proxy"  v-if="pool.selTab === 'proxy'">
            <span slot="label" class="fontClass">日志</span>
            <ProxyLogger :pool-i-d="pool.id" :pool-name="pool.name" />
          </el-tab-pane>
          <el-tab-pane name="console" v-if="pool.selTab === 'console' || pool.selTab === 'dev' || pool.selTab === 'capture'">
            <span slot="label" class="fontClass">程序日志</span>
            <ConsoleLogger :pool-i-d="pool.id" :pool-name="pool.name" />
          </el-tab-pane>
          <el-tab-pane name="dev" v-if="(pool.selTab === 'console' || pool.selTab === 'dev' || pool.selTab === 'capture') && $store.getters.devMode === 1">
            <span slot="label" class="fontClass">dev</span>
            <ProxyLogger pool-i-d="0" :pool-name="dev" />
          </el-tab-pane>
          <el-tab-pane name="capture" v-if="(pool.selTab === 'console' || pool.selTab === 'dev' || pool.selTab === 'capture') && $store.getters.devMode === 1">
            <span slot="label" class="fontClass">capture</span>
            <ProxyLogger pool-i-d="-1" :pool-name="capture" />
          </el-tab-pane>
          <!-- el-tab-pane name="miners">
            <span slot="label" class="fontClass">矿机日志</span>
            <MinersLogger v-if="pool.selTab === 'miners'" />
          </el-tab-pane-->
        </el-tabs>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>

import ProxyLogger from "./ProxyLogger";
import ConsoleLogger from "./ConsoleLogger";
import MinersLogger from "./MinersLogger";

export default {
  name: 'Logger',
  data() {
    return {
      selPool: '',
      pools: [],
    };
  },
  components: {
    ProxyLogger,
    MinersLogger,
    ConsoleLogger,
  },
  mounted() {
    this.flush();
  },
  activated() {
    this.flush();
  },
  methods: {
    flush() {
      const that = this
      this.$axios.get("proxies/name").then(resp => {
        that.pools = []
        resp.data.forEach(function (item) {
          that.pools.push({
            selTab: 'proxy',
            ...item,
          })
        })
        that.pools.push({
          selTab: 'console',
          id: 9999,
          name: '开发者日志',
        })
        if (that.pools.length > 0) {
          if (that.selPool === "" || that.selPool === "0") {
            that.selPool = that.pools[0].name
          }
        }
      })
    },
  }
}
</script>
