<template>
  <div>
    <div v-chat-scroll="{always: true, smooth: true, notSmoothOnInit: true}" style="height: 70vh; list-style: none; overflow-y:scroll">
      <div v-for="log in logs">
        <span>
          {{ log }}
        </span>
        <p></p>
      </div>
    </div>
  </div>
</template>

<script>
import {saveAs} from 'file-saver';

export default {
  name: 'ProxyLogger',
  data() {
    return {
      logs: []
    };
  },
  created() {
    this.captureLog()
  },
  methods: {
    captureLog() {
      const tm = (new Date()).valueOf()
      this.$axios.get("capture/log?tm="+tm).then(resp => {
        const ds = resp.data.split('\n')
        ds.forEach(item => {
          if (item !== "") {
            this.logs.push(item)

            if (this.logs.length > 500) {
              this.logs.shift()
            }
          }
        })
      })
    },
  }
}
</script>
