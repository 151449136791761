<template>
  <div>
    <el-dialog
        title="添加受控端"
        :visible.sync="dialogVisible"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
        :destroy-on-close="true"
        width="40%">
      <el-form label-position="top" label-width="80px" :model="addForm" :rules="rules" ref="addGCForm" >
        <el-form-item label="名称" prop="name">
          <el-input v-model="addForm.name"></el-input>
        </el-form-item>
        <el-form-item label="Web主页地址" prop="web">
          <el-input v-model="addForm.web" placeholder="https://some-web.com:8899"></el-input>
        </el-form-item>
        <el-form-item label="凭证" prop="token">
          <el-input v-model="addForm.token"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="hideGCDialog">取 消</el-button>
        <el-button type="primary" @click="submitForm('addGCForm')">确 定</el-button>
      </span>
    </el-dialog>
    <div style="display: flex; flex-direction: row;">
      <div style="width: 20px; padding: 20px">
        <el-slider v-model="itemHeight" :min="180" :max="1000" vertical height="400px" />
      </div>
      <div style="display: flex; flex-direction: column;grid-gap: 20px; flex-grow: 1">
        <div style="display: flex;grid-gap: 20px; justify-content: center; align-items: center">
          <span style="text-align: center">受控凭证: {{ token }}</span>
          <el-button type="primary" class="xToken" icon="el-icon-document-copy" circle :data-clipboard-text="token" @click="copyToken"></el-button>
          <el-checkbox v-model="allowRemoteLogin" @change="allowQuickLoginChanged">允许快速登录</el-checkbox>
          <el-popconfirm
              title="重置凭证会导致原凭证失效，确定要重置吗？"
              @confirm="resetToken"
          >
            <el-button type="warning" slot="reference">重置凭证</el-button>
          </el-popconfirm>
        </div>
        <div style="display: flex;grid-gap: 20px; justify-content: center; align-items: center;padding: 0 50px">
          <el-button icon="el-icon-circle-plus" @click="dialogVisible=true">添加受控端</el-button>
          <el-select style="flex-grow: 1" v-model="selectedClientIDs" multiple
                     @change="selectedClientIDsChange" placeholder="请选择要显示的受控客户端">
            <el-option
                v-for="item in clientIDs"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
          <el-checkbox-group  v-model="viewDataItems">
            <el-checkbox-button @change="flushClientsInfos()">过滤无数据代理</el-checkbox-button>
          </el-checkbox-group>
          <el-button icon="el-icon-refresh" circle @click="flushClientsInfos"></el-button>
          <el-switch
              v-model="refreshAuto"
              active-text="自动刷新"
              inactive-text="手动刷新"
              @change="refreshAutoChanged"
          >
          </el-switch>
        </div>
        <div style="width: 100%; display: flex; flex-flow:wrap;grid-gap: 20px;justify-content: center; padding: 20px">
          <div v-for="client in clients" class="box-panel" :style="'width: 300px; height: ' + itemHeight + 'px;'">
            <GroupControlItem :client="client" @remove="removeClient" @goto="gotoClient" class="gc-item" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GroupControlItem from './GroupControlItem'
import Clipboard from 'clipboard'

export default {
  name: 'GroupControl',
  data() {
    return {
      itemHeight: 300,
      refreshAuto: true,
      viewDataItems: false,
      refreshTimerID: null,
      token: '-',
      allowRemoteLogin: false,
      clientIDs: [],
      selectedClientIDs: [],
      clients: [],
      dialogVisible: false,
      addForm: {
        'name': '',
        'web': '',
        'token': ''
      },
      rules: {
        name: [
          { required: true, message: '请输入受控端显示名称', trigger: 'blur' },
        ],
        web: [
          { required: true, message: '请输入受控端主页地址', trigger: 'blur' },
        ],
        token: [
          { required: true, message: '请输入受控凭证', trigger: 'blur' },
        ],
      }
    };
  },
  components: {
    GroupControlItem
  },
  mounted() {
    this.selectedClientIDs = this.$store.getters.gcShowClients;
    this.flushClientsList();
    this.refreshAutoChanged()
  },
  beforeDestroy() {
    this.stopRefreshAuto()
  },
  methods: {
    allowQuickLoginChanged() {
      this.$axios.post("/gc/quicklogin/"+this.allowRemoteLogin, {}).then(resp => {
        this.$message.success('修改快速登录标记成功')
      }).catch((err) => {
        console.log(err)
        this.$message.error(err.status + ": " + err.data)
      })
    },
    stopRefreshAuto() {
      if (this.refreshTimerID) {
        clearInterval(this.refreshTimerID);
        this.refreshTimerID = null
      }
    },
    startRefreshAuto() {
      this.stopRefreshAuto()

      this.refreshTimerID = setInterval(this.flushClientsInfos, 1000*30);
    },
    refreshAutoChanged() {
      if (this.refreshAuto) {
        this.startRefreshAuto()
      } else {
        this.stopRefreshAuto()
      }
    },
    selectedClientIDsChange() {
      this.$store.commit('gcShowClients', this.selectedClientIDs)
      this.flushClientsInfos()
    },
    flushClientsInfos() {
      this.$axios.post("/gc/client/infos", {
        ids: this.selectedClientIDs,
        viewDataItems: this.viewDataItems,
      }).then(resp => {
        this.clients = resp.data.infos
      }).catch((err) => {
        console.log(err)
        this.$message.error(err.status + ": " + err.data)
      })
    },
    flushClientsList() {
      const that = this;
      this.$axios.get("/gc/home").then(resp => {
        that.token = resp.data.token
        that.allowRemoteLogin = resp.data.tokenAllowQuickLogin
        that.clientIDs = resp.data.clients
        if (that.clientIDs === null) {
          that.clientIDs = []
        }

        let showClientChange = false

        for(let sidx = that.selectedClientIDs.length -1; sidx >= 0 ; sidx--){
          let found = false
          for (let idx=0; idx<that.clientIDs.length; idx++) {
            if (that.clientIDs[idx].value === that.selectedClientIDs[sidx]) {
              found = true

              break
            }
          }
          if (!found) {
            that.selectedClientIDs.splice(sidx, 1);
            showClientChange = true;
          }
        }

        if (that.selectedClientIDs.length === 0) {
          for(let sidx = that.clientIDs.length -1; sidx >= 0 ; sidx--){
            that.selectedClientIDs.push(that.clientIDs[sidx].value)
            showClientChange = true;
          }
        }

        if (showClientChange) {
          that.$store.commit('gcShowClients', that.selectedClientIDs)
        }

        this.selectedClientIDs = that.$store.getters.gcShowClients;
        this.flushClientsInfos()
      })
      for (let idx=0; idx<10; idx++) {
        this.clientIDs.push({
          label: "label"+idx,
          value: "value"+idx,
        })
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$axios.post("/gc/client/add", {
            name: this.addForm.name,
            web: this.addForm.web,
            token: this.addForm.token,
          }).then(resp => {
            this.clientIDs.push({
              label:this.addForm.name,
              value:resp.data.id,
            })

            let shows = this.$store.getters.gcShowClients;
            shows.push(resp.data.id);
            this.$store.commit('gcShowClients', shows);
            this.selectedClientIDs = this.$store.getters.gcShowClients;
            this.hideGCDialog()
            this.flushClientsInfos()
            this.$message('添加受控端成功, 受控ID为' + resp.data.id);
          }).catch((err) => {
            console.log(err)
            this.$message.error(err.status + ": " + err.data)
          })
        } else {
          return false;
        }
      });
    },
    hideGCDialog() {
      this.dialogVisible = false
      this.addForm.name = ''
      this.addForm.web = ''
      this.addForm.token = ''
    },
    resetToken() {
      this.$axios.post("/gc/token", {}).then(resp => {
        this.flushClientsList()
      }).catch((err) => {
        console.log(err)
        this.$message.error(err.status + ": " + err.data)
      })
    },
    removeClient(cid) {
      this.$axios.post("/gc/client/remove/"+cid, {}).then(resp => {
        this.flushClientsList()
      }).catch((err) => {
        console.log(err)
        this.$message.error(err.status + ": " + err.data)
      })
    },
    gotoClient(cid) {
      this.$axios.get("/gc/client/goto/"+cid, {}).then(resp => {
        window.open(resp.data.url, '_blank')
      }).catch((err) => {
        console.log(err)
        this.$message.error(err.status + ": " + err.data)
      })
    },
    copyToken() {
      let clipboard = new Clipboard('.xToken')
      clipboard.on('success', e => {
        this.$message.success("复制成功")
        clipboard.destroy()
      })
      clipboard.on('error', e => {
        this.$message.error('该浏览器不支持自动复制')
        clipboard.destroy()
      })
    },
  }
}

</script>

<style>
.gc-item {
  width: 300px;
}

.box-panel {
  text-align: center;
  background-color: rgba(80, 255, 255, 0.02);

  -moz-border-radius: .6em;
  -webkit-border-radius: .6em;
  border-radius: .6em;

  -webkit-box-shadow: 3px 3px 6px #666;
  -moz-box-shadow: 3px 3px 6px #666;
  box-shadow: 3px 3px 6px #666;

  padding-left: 4px;
  padding-right: 4px;
  padding-top: 8px;
}
</style>