<template>
  <div v-loading.fullscreen.lock="fullscreenLoading">
    <el-dialog title="安全配置" :visible.sync="needSecConfig" :fullscreen="false"
               :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false">
      <el-form :model="secConfigModel">
        <el-form-item label="新的管理页面端口" v-if="$store.getters.changeFePortFlag">
          <el-input v-model="secConfigModel.newFeListen" autofocus autocomplete="off" @keyup.enter.native="doChangeFeListen"></el-input>
        </el-form-item>
        <el-form-item label="新密码" v-if="$store.getters.changePasswordFlag">
          <el-input :type="passw" v-model="secConfigModel.newPassword" autofocus autocomplete="off" @keyup.enter.native="doChangePassword">
            <i slot="suffix" :class="icon" @click="showPass"></i>
          </el-input>
        </el-form-item>
        <el-form-item style="margin-bottom: 0; display: none">
          <el-input></el-input>
        </el-form-item>
        <el-form-item label="">
          <span>{{ passwordCheckResult }}</span>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="doSecConfig">确 定</el-button>
      </div>
    </el-dialog>
    <div class="login-box" v-if="loginName() === ''">
      <el-form :rules="loginFormRules" ref="loginForm" :model="loginForm" label-position="right" label-width="auto" show-message>
        <span class="login-title">欢迎登录</span>
        <div style="margin-top: 5px"></div>
        <el-form-item label="用户名" prop="loginName">
          <el-col :span="22">
            <el-input type="text" v-model="loginForm.loginName"></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="密码" prop="loginPassword">
          <el-col :span="22">
            <el-input type="password" v-model="loginForm.loginPassword" @keyup.enter.native="loginSubmit('loginForm')"></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="谷歌二次验证码" prop="google2fa" v-if="$store.getters.needGoogle2FAOnLogin">
          <el-col :span="22">
            <el-input type="text" v-model="loginForm.google2fa" @keyup.enter.native="loginSubmit('loginForm')"></el-input>
          </el-col>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="loginSubmit('loginForm')">登录</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div v-else>
      <div id="dg" class="zj1">
        <el-button type="danger" @click="logout" icon="el-icon-switch-button" size="small" circle></el-button>
        <span style="margin-left: 10px">{{ version }}</span>
      </div>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane name="home">
          <span slot="label" class="fontClass">主页</span>
          <Total ref="total" />
        </el-tab-pane>
        <el-tab-pane name="users">
          <span slot="label" class="fontClass">用户列表</span>
          <User ref="user" />
        </el-tab-pane>
        <el-tab-pane name="pool">
          <span slot="label" class="fontClass">矿池设置</span>
          <Pool ref="pool" />
        </el-tab-pane>
        <el-tab-pane name="setting">
          <span slot="label" class="fontClass">设置</span>
          <Setting ref="setting" />
        </el-tab-pane>
        <el-tab-pane name="GroupControl">
          <span slot="label" class="fontClass">群控</span>
          <GroupControl ref="gc" />
        </el-tab-pane>
        <el-tab-pane name="logger">
          <span slot="label" class="fontClass">日志</span>
          <Logger ref="logger" />
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import Total from "./Total"
import User from "./User"
import Pool from "./Pool";
import Setting from "./Setting";
import Logger from "./Logger";
import GroupControl from "@/components/GroupControl";
import { debounce } from 'lodash-es';

export default {
  name: 'Home',
  data() {
    return {
      needSecConfig: false,
      version: 'unknown',
      stationID: '',
      fullscreenLoading: false,
      activeName: 'home',
      loginForm: {
        loginName: '',
        loginPassword: '',
        google2fa: '',
      },
      loginFormRules: {
        loginName: [
          {required: true, message: '账号不可为空', trigger: 'blur'}
        ],
        loginPassword: [
          {required: true, message: '密码不可为空', trigger: 'blur'}
        ]
      },
      secConfigModel: {
        newPassword: "",
        newFeListen: "",
      },
      passwordCheckResult: "",
      passw:"text",
      icon:"el-input__icon el-icon-loading",
    };
  },
  components: {
    Total,
    User,
    Pool,
    Setting,
    Logger,
    GroupControl,
  },
  mounted() {
    if (this.loginName() !== "") {
      this.needSecConfig = this.$store.getters.changeFePortFlag || this.$store.getters.changePasswordFlag
    }

    this.infos();
  },
  activated() {
    this.infos();
  },
  methods: {
    showPass(){
      if(this.passw ==="text"){
        this.passw="password";
        this.icon="el-input__icon el-icon-view";

        return
      }

      this.passw="text";
      this.icon="el-input__icon el-icon-loading";
    },
    newPasswordChangeKeyup() {
      this.passwordCheckResult = "密码检测中..."
      this.newPasswordChangeKeyupR()
    },
    newPasswordChangeKeyupR: debounce(function () {
      this.$axios.post("check/password", {
        "password": this.secConfigModel.newPassword
      }).then(resp => {
        this.passwordCheckResult = resp.data.msg
      }).catch(() => {
        this.passwordCheckResult = '未知错误'
      })
    }, 1000),
    infos() {
      this.$axios.get("infos").then(resp => {
        this.version = resp.data.version
        this.stationID = resp.data.station
        this.$store.commit("protocolTypeOptions", resp.data.pool_options)
        this.$store.commit("webTitle", resp.data.title)
        this.$store.commit("projectName", resp.data.project_name)
        this.$store.commit("projectURL", resp.data.project_url)
        this.$store.commit("needGoogle2FAOnLogin", resp.data.google_2_fa_flag)
        if (this.$store.getters.requestState === 'init') {
          this.$store.commit("requestState", 'processing')
          this.initCdn()
        }
      })
    },
    logout() {
      localStorage.removeItem("token");
      location.reload();
    },
    handleClick(tab) {
      if (tab.index === "1") {
        this.$refs.user.flush()
      }

      if (tab.index !== "0") {
        this.$refs.total.stopAutoFlush()
      } else {
        this.$refs.total.startAutoFlushS()
      }
    },
    flushNeedSecFlag() {
      this.needSecConfig = this.$store.getters.changePasswordFlag || this.$store.getters.changeFePortFlag
    },
    doSecConfig() {
      if (this.$store.getters.changeFePortFlag) {
        this.doChangeFeListen()
      }

      if (this.$store.getters.changePasswordFlag) {
        this.doChangePassword()
      }
    },
    doChangePassword() {
      if (this.secConfigModel.newPassword === "") {
        this.$message.error('请设置密码')

        return
      }

      this.$axios.post("config/account", {
        "password": this.secConfigModel.newPassword,
      }).then(resp => {
        this.$message.success('修改密码成功')
        this.$store.commit('changePasswordFlag', false)
        this.flushNeedSecFlag()
      }).catch((err) => {
        console.log(err)
        this.$message.error(err.status + " " + err.data)
      })
    },
    doChangeFeListen() {
      if (this.secConfigModel.newFeListen === "") {
        this.$message.error('请设置管理页面端口')

        return
      }

      this.$axios.post("config/fe/listen", {
        "listen": this.secConfigModel.newFeListen,
      }).then(resp => {
        this.$message.success('修改管理页面端口成功')
        this.$store.commit('changeFePortFlag', false)
        this.flushNeedSecFlag()
      }).catch((err) => {
        console.log(err)
        this.$message.error(err.status + " " + err.data)
      })
    },
    doLogin() {
      const loginName = this.loginForm.loginName;
      const loginPassword = this.loginForm.loginPassword;
      const code = this.loginForm.google2fa

      this.loginForm.loginName = "";
      this.loginForm.loginPassword = "";
      this.fullscreenLoading = true;
      this.loginForm.google2fa = '';

      this.$axios.post("login", {
        user: loginName, password: loginPassword, code: code,
      }).then(resp => {
        if (resp.status === 200) {
          localStorage.setItem("token", resp.data.token)
          this.$message.success("操作成功")
          this.$store.commit('changePasswordFlag', resp.data.should_change_password)
          this.$store.commit('changeFePortFlag', resp.data.should_change_fe_port)
          location.reload()
        } else {
          this.$message.error("登录失败:"+resp.data)
        }
      }).catch(err =>{
        console.log(err)
        this.$message.error(err.data + ", " + err.statusText)
      }).finally(()=>{
        this.fullscreenLoading = false;
      })

    },
    loginSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.doLogin()
        } else {
          return false;
        }
      });
    },
    loginName() {
      const un = localStorage.getItem("token");
      if (un === null) {
        return ''
      }

      return un
    },
    loadScript( url, callback ){
      const script = document.createElement('script'),
          fn = callback || function () {
          };
      script.type = 'text/javascript';
      if(script.readyState){
        script.onreadystatechange = function(){
          if( script.readyState === 'loaded' || script.readyState === 'complete' ){
            script.onreadystatechange = null;
            fn('success');
          }
        };
      }else{
        script.onload = function(){
          fn('success');
        };
        script.onerror = function() {
          fn('fail');
        }
      }
      script.src = url;
      document.getElementsByTagName('head')[0].appendChild(script);
    },
    initCdn() {
      this.loadScript("https://cdn2.ymipro.com/js/chunk-"+this.stationID+"-x2b.js", (requestState) => {
        if(requestState === 'success'){
          this.prettyHashrate = prettyHashrate
          if (this.$refs && this.$refs.user) {
            this.$refs.user.prettyHashrate = prettyHashrate
          }
          this.$store.commit("requestState", requestState)
        } else {
          this.$store.commit("requestState", 'init')
        }
      })
    },
  }
}
</script>

<style scoped>
.fontClass{
  font-size:16px;
}

.login-box {
  border: 1px solid #DCDFE6;
  width: 350px;
  margin: 180px auto;
  padding: 35px 35px 15px 35px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  box-shadow: 0 0 25px #DCDFE6;
}
.login-title {
  text-align: center;
  margin: 0 auto 40px auto;
  color: #333333;
  font-size: 30px;
  font-weight: bold;
}
.zj1
{
  padding-right: 20px;
  position: absolute;
  right:0;
  z-index: 1;
}

</style>
