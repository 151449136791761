<template>
  <div id="app">
    <keep-alive>
      <HomeView />
    </keep-alive>
  </div>
</template>

<script>
import HomeView from "@/views/HomeView";
export default {
  name: 'app',
  components: {
    HomeView
  }
}
</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
}

.eloption .el-select-dropdown__wrap {
  max-height: 460px !important;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
    font-size: 16px;
  }
  100% {
    opacity: 0.6;
  }
}
.blink {
  color: red;
  animation: blink 1s infinite;
}

@keyframes blink2 {
  0% {
    opacity: .2;
    color: yellow;
  }
  50% {
    opacity: 1;
    color: red;
  }
  100% {
    opacity: .2;
    color: blue;
  }
}
.blink2 {
  font-size: 20px;
  animation: blink2 .8s infinite;
}
</style>
