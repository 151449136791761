<template>
  <div>
    <div v-chat-scroll="{always: true, smooth: true, notSmoothOnInit: true}" style="height: 70vh; list-style: none; overflow-y:scroll">
      <div v-for="log in logs">
        <span style="color: darkcyan">
          {{ log.at }}
        </span>
        &nbsp;
        <span>
          {{ log.text }}
        </span>
        <p></p>
      </div>
    </div>
    <el-button type="primary" @click="download">下载完整日志<i class="el-icon-download el-icon--right"></i></el-button>
  </div>
</template>

<script>
import {saveAs} from 'file-saver';

export default {
  name: 'ProxyLogger',
  props: ["poolID", "poolName"],
  data() {
    return {
      logs: [],
      runningFlag: false,
    };
  },
  created() {
    this.runningFlag = true
    this.initWebSocket()
  },
  destroyed() {
    this.runningFlag = false
    if (this.websock) {
      this.websock.close()
    }
  },
  methods: {
    download() {
      const tm = (new Date()).valueOf()
      this.$axios.get("download/proxy/"+this.poolID+"/log?tm="+tm, {responseType: 'blob'}).then(resp => {
        saveAs(resp.data, this.poolName + "_"+tm+".log");
      })
    },
    initWebSocket() {
      if (!this.runningFlag) {
        return
      }
      let scheme = "ws:"
      if (window.location.protocol === "https:") {
        scheme = "wss:"
      }

      this.websock = new WebSocket(scheme +"//"+window.location.host+"/xws/ws/proxy/"+this.poolID
          +"?token="+localStorage.getItem("token"));
      if (this.websock === undefined) {
        alert("kao")
      }
      this.websock.onmessage = this.onWsMessage;
      this.websock.onopen = this.onWsOpen;
      this.websock.onerror = this.onWsError;
      this.websock.onclose = this.oncWsClose;
    },
    onWsOpen(){
      console.log('连接')
    },
    onWsError(){
      setTimeout(this.initWebSocket, 1000)
    },
    onWsMessage(e) {
      const ds = e.data.split('\n')
      ds.forEach(item => {
        if (item !== "") {
          this.logs.push({
            at: item.slice(0, 20),
            text: item.slice(20),
          })

          if (this.logs.length > 500) {
            this.logs.shift()
          }
        }
      })
    },
    wsSend(Data){
      this.websock.send(Data);
    },
    oncWsClose(e){  //关闭
      console.log('断开连接',e);
    },
  }
}
</script>
