<template>
  <div>
    <el-row :gutter="12">
      <el-col align="middle" :xs="24" :sm="12" :md="12" :lg="4" :xl="4">
        <el-card shadow="always">
          <div style="padding: 14px;">
            <span>币种</span>
            <div class="bottom clearfix">
              <el-select v-model="homeProtocolType" placeholder="请选择" style="padding-top: 10px"
              popper-class="eloption" :popper-append-to-body="true"
              @change="(val)=> viewProtocolTypeChange(val)" size="mini">
                <el-option
                    v-for="item in $store.getters.protocolTypeOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </div>
          </div>
        </el-card>
      </el-col>
      <el-col align="middle" :xs="24" :sm="12" :md="12" :lg="4" :xl="4">
        <el-card shadow="always">
          <div style="padding: 14px;">
            <span>在线矿机</span>
            <div class="bottom clearfix">
              <el-button type="text" class="button">{{ onlineMinersInfo }}</el-button>
            </div>
          </div>
        </el-card>
      </el-col>
      <el-col align="middle" :xs="24" :sm="12" :md="12" :lg="4" :xl="4">
        <el-card shadow="always">
          <div style="padding: 14px;">
            <span>离线矿机</span>
            <div class="bottom clearfix">
              <el-button type="text" class="button">{{ offlineMinersInfo }}</el-button>
            </div>
          </div>
        </el-card>
      </el-col>
      <el-col align="middle" :xs="24" :sm="12" :md="12" :lg="4" :xl="4" >
        <el-card shadow="always">
          <div style="padding: 14px;">
            <span>总算力</span>
            <div class="bottom clearfix">
              <el-button type="text" class="button">{{ hashratesInfo }}</el-button>
            </div>
          </div>
        </el-card>
      </el-col>
      <el-col align="middle" :xs="24" :sm="12" :md="12" :lg="4" :xl="4" >
        <el-card shadow="always">
          <div style="padding: 14px;">
            <span>代理运行</span>
            <div class="bottom clearfix">
              <el-button type="text" class="button">{{ upDuration }}</el-button>
            </div>
          </div>
        </el-card>
      </el-col>
      <el-col align="middle" :xs="24" :sm="12" :md="12" :lg="4" :xl="4" >
        <el-card shadow="always" body-style="padding: 10px 20px">
          <div style="text-align: left;">
            <span class="os-line">CPU <span class="os-line2"><span :class="cpuWarnFlag? 'blink': ''">{{ cpuPercent }}</span></span></span>
            <br>
            <span class="os-line">内存 <span :class="memWarnFlag? 'blink os-line2': 'os-line2'">{{ memPercent }}</span></span>
            <br>
            <span class="os-line3">&nbsp;&nbsp;{{ mem }}</span>
            <br>
            <span class="os-line">磁盘 <span :class="diskWarnFlag? 'blink os-line2': 'os-line2'">{{ diskPercent }}</span></span>
            <br>
            <span class="os-line3">&nbsp;&nbsp;{{ disk }}</span>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <div style="margin-top: 20px">
      <el-row>
        <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="4">
          <el-table
              :data="proxies"
              size="mini"
              :highlight-current-row="true"
              ref="proxyList"
              @current-change="proxyChanged"
              style="width: 100%">
            <el-table-column
                prop="name"
                label="矿池">
            </el-table-column>
            <el-table-column
                prop="online_miners_info"
                label="机器数">
            </el-table-column>
            <el-table-column
                prop="total_hashrate"
                label="总算力">
            </el-table-column>
          </el-table>
        </el-col>
        <el-col :xs="24" :sm="12" :md="16" :lg="18" :xl="20" >
          <EChart ref="chart_line_one" @curveSpanChanged="flushHashrate" />
        </el-col>
      </el-row>
    </div>
    <div style="text-align:center">
       {{ $store.getters.projectName }} <el-link type="primary" icon="el-icon-s-home" :href="$store.getters.projectURL" target="_blank">{{ $store.getters.projectURL }}</el-link>
    </div>
  </div>

</template>

<script>
import EChart from "@/components/EChart";

export default {
  name: 'Total',
  data() {
    return {
      onlineMinersInfo: '',
      offlineMinersInfo: '',
      hashratesInfo: '',
      timer: null,
      proxies: [],
      proxyID: 0,
      upDuration: '',
      cpuPercent: '-',
      cpuWarnFlag: false,
      mem: '-',
      memPercent: '-',
      memWarnFlag: true,
      disk: '-',
      diskPercent: '-',
      diskWarnFlag: false,
    }
  },
  mounted() {
    this.flush();
  },
  activated() {
    this.startAutoFlushS()
  },
  deactivated() {
    this.stopAutoFlush()
  },
  computed: {
    homeProtocolType: {
      set(obj) {
        this.$store.commit("homeProtocolType", obj)
      }, get() { //后
        return this.$store.getters.homeProtocolType;
      }
    }
  },
  components: {
    EChart,
  },
  methods: {
    viewProtocolTypeChange(newPT) {
      this.$store.commit("homeProtocolType", newPT)
      this.flush()
    },
    proxyChanged(currentRow, oldCurrentRow) {
      this.proxyID = currentRow.proxy_id
      this.flushHashrate()
    },
    startAutoFlushS() {
      this.stopAutoFlush()
      this.timer = setInterval(() => {
        this.flush()
      },5000)
    },
    stopAutoFlush() {
      if (this.timer !== null) {
        clearInterval(this.timer)
        this.timer = null
      }
    },
    flush() {
      const that = this
      this.$axios.get("web/home/stat?pt="+this.$store.getters.homeProtocolType).then(resp => {
        that.onlineMinersInfo = resp.data.online_miners_info
        that.offlineMinersInfo = resp.data.offline_miners_info
        that.hashratesInfo = resp.data.total_hashrate
        that.upDuration = resp.data.up
        that.proxies = resp.data.proxies
        that.cpuPercent = resp.data.cpuPercent
        that.cpuWarnFlag = resp.data.cpuWarnFlag
        that.mem = resp.data.mem
        that.memPercent = resp.data.memPercent
        that.memWarnFlag = resp.data.memWarnFlag
        that.disk = resp.data.disk
        that.diskPercent = resp.data.diskPercent
        that.diskWarnFlag = resp.data.diskWarnFlag
        this.$store.commit("devMode", resp.data.dev_flag)
        this.$store.commit("profileAddress", resp.data.profile_address)
        if (that.proxies.length > 0 && that.$refs.proxyList !== undefined) {
          that.proxies.forEach(function (item) {
            if (item.proxy_id === that.proxyID) {
              that.$refs.proxyList.setCurrentRow(item)
            }
          })
        }
      })
    },
    flushHashrate() {
      const that = this
      let allU = "hashrate/" + this.proxyID + "?pt=" + this.$store.getters.homeProtocolType;
      if (that.$refs.chart_line_one !== undefined) {
        allU += "&s="+this.$refs.chart_line_one.curveSpan
      }
      this.$axios.get(allU).then(resp => {
        let hashrates = [];
        resp.data.hashrates.forEach(function (item) {
          hashrates.push(parseFloat(item))
        })
        let cheat_hashrates =[];
        resp.data.cheat_hashrates.forEach(function (item) {
          cheat_hashrates.push(parseFloat(item))
        })
        if (that.$refs.chart_line_one !== undefined) {
          that.$refs.chart_line_one.show(resp.data.time_s, hashrates, cheat_hashrates,  resp.data.unit)
        }
      })
    }
  }
}
</script>

<style>
.el-table .cell {
  padding: 6px !important;
}

 tr.current-row > td,
.el-table__body tr:hover > td {
  background: #ecf5ff;
}

.el-table__body, .el-table__footer, .el-table__header {
  border-collapse: separate;
  border-spacing: 0px 1px;
  background-color: #eeeeee;
}

.el-row {
  display: flex;
  flex-wrap: wrap;
}

.el-col {
  align-items: stretch;
}

.el-card {
  height:100%;
}

.os-line {
  font-size: 14px;
}

.os-line2 {
  font-size: 10px;
  color: green;
}

.os-line3 {
  font-size: 10px;
  color: grey;
}
</style>